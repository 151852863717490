import React, { useEffect, useRef, forwardRef, SyntheticEvent, useState } from 'react';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import deepmerge from 'deepmerge';

import CalendarIcon from 'styles/icons/CalendarIcon';
import { UploadIcon } from 'styles/icons/UploadIcon';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import dayjs from 'utils/dayjs';

import { ICertificateItem } from 'store/types/certificates';

import fetchProductsWithModulesWorks from 'store/query/common/fetchProductsWithModulesWorks';
import fetchProductPrices from 'store/query/common/fetchProductPrices';

import { createConsultantCompany, saveCertificateFile } from 'store/query/consultant';
import createConsultantCertificate from 'store/query/consultant/createConsultantCertificate';
import updateConsultantCertificate from 'store/query/consultant/updateConsultantCertificate';

import { getMeSelector } from 'store/selectors/getUserSelector';
import {
  categoryProductsSelector,
  rolesSelector,
  modulesSelector,
  workTypesSelector,
  productPricesSelector,
} from 'store/selectors/getCommonSelector';

import { BootstrapInput } from 'components/CustomFields';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import DateTextInput from 'components/CustomFields/DateTextInput';
import useSearchCompanyByName from 'hooks/useSearchCompanyByName';
import { ICompany } from 'store/types/company';
import { customFilter } from 'helpers/customFilter';

import { onTypeaheadItemChange } from 'utils/orderCreationHelpers';
import { filterIsDeprecated } from 'helpers/decorators';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import { StyledMasonry } from '../../CustomFields/CustomAutocomplete/styled';

interface ICertificateForm {
  onClose: () => void;
  defaultData?: ICertificateItem;
  showIntro?: boolean;
}

const CertificateForm: React.FunctionComponent<ICertificateForm> = ({
  onClose,
  showIntro,
  defaultData,
}) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);
  const user = useAppSelector(getMeSelector);
  const roles = useAppSelector(rolesSelector);

  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [joyrideZIndex, setJoyrideZIndex] = useState(10000);

  const {
    reset,
    control,
    clearErrors,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setValue,
    resetField,
    watch,
  } = useFormContext();

  const currentProduct = watch('product');
  const primaryModule = watch('primaryModule');
  const primaryWorkType = watch('primaryWorkType');
  const selectedFile = watch('file');
  const issueDate = watch('issueDate');
  const expirationDate = watch('expirationDate');

  const modulesListByProduct = filterIsDeprecated(modulesList, currentProduct?.id);
  const filteredModulesListByProduct = modulesListByProduct.filter(module => module.isActive);
  const worksListByProduct = filterIsDeprecated(worksList, currentProduct?.id);

  const {
    companies,
    setSearchTerm,
    isLoading: isCompaniesLoading,
    setLoading,
  } = useSearchCompanyByName({});

  const handleCompanySearch = (event: SyntheticEvent, value: string) => {
    setLoading(true);
    setSearchTerm(value);
  };

  const handleCreateCompany = (company: ICompany) => {
    if (company.create) {
      dispatch(
        createConsultantCompany({
          company: {
            name: company.name,
            roleId: roles?.find(({ name }) => name === 'Manager')?.id || 4,
            ownerId: user.id,
          },
        }),
      )
        .unwrap()
        .then(data => {
          setValue(`company`, {
            ...data[0],
            create: `Add new company: "${company.name}"`,
          });
          setValue(`companyId`, data[0].id);
        });
    }
    setValue(`companyId`, company.id);
  };

  useEffect(() => {
    dispatch(fetchProductsWithModulesWorks());
  }, [dispatch]);

  useEffect(() => {
    if (defaultData) {
      setValue('name', defaultData.name || '');
      setValue('company', defaultData.organization || '');
      setValue('issueDate', defaultData.issueDate || '');
      setValue('expirationDate', defaultData?.expirationDate || '');
      setValue('certificateId', defaultData?.certificateId || '');
      setValue('product', defaultData?.product || '');
      setValue(
        'modules',
        defaultData?.modulesIds
          ? filteredModulesListByProduct.filter(module =>
              defaultData.modulesIds.includes(module.id),
            )
          : '',
      );
      setValue(
        'works',
        defaultData?.worksIds
          ? worksListByProduct.filter(work => defaultData.worksIds.includes(work.id))
          : '',
      );
      setValue('file', '');
    } else {
      reset(
        {
          name: '',
          company: '',
          issueDate: '',
          expirationDate: '',
          certificateId: '',
          product: '',
          modules: '',
          works: '',
          file: '',
        },
        {
          keepErrors: false,
          keepDirty: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        },
      );
    }
    // If we include filteredModulesListByProduct in the deps, it cases infinite loading to the form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulesList, worksList]);

  const onSubmit = async (values: any) => {
    if (
      (!values?.file || !defaultData?.files) &&
      !values.name &&
      !values.company &&
      !values.issueDate
    ) {
      return;
    }
    const certificate = {
      name: values.name.trim(),
      issueDate: dayjs(values.issueDate).toISOString(),
      expirationDate: values.expirationDate ? dayjs(values.expirationDate).toISOString() : null,
      certificateId: values.certificateId || null,
      productId: values.product?.id || null,
      organizationId: values.company?.id || null,
      modulesIds: values.modules ? values.modules.map((module: any) => module.id) : null,
      worksIds: values.works ? values.works.map((work: any) => work.id) : null,
    };

    if (values.file) {
      await dispatch(saveCertificateFile({ file: values.file }))
        .unwrap()
        .then((data: any) => {
          if (defaultData) {
            dispatch(
              updateConsultantCertificate({
                certificate: {
                  ...certificate,
                  publicFileIds: [data.id],
                  id: defaultData.id,
                },
                key: id || user.id,
              }),
            )
              .unwrap()
              .then(onClose);
          } else {
            dispatch(
              createConsultantCertificate({
                certificate: {
                  ...certificate,
                  publicFileIds: [data.id],
                },
                key: id || user.id,
              }),
            )
              .unwrap()
              .then(onClose);
          }
        });
    } else {
      if (defaultData) {
        await dispatch(
          updateConsultantCertificate({
            certificate: {
              ...certificate,
              id: defaultData.id,
              publicFileIds: defaultData.files.map(item => item.id),
            },
            key: id || user.id,
          }),
        )
          .unwrap()
          .then(onClose);
      }
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex(10000);
    }
  };

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];

        if (!defaultData?.files) {
          stepsList.push({
            target: '.certificate-file-input',
            title: 'Upload Your Certification',
            content:
              'Please upload your certification to complete this section. Supported file formats include .png, .pdf, and .jpeg.',
            placement: 'bottom',
            disableBeacon: true,
          });
        }

        if (!defaultData?.name) {
          stepsList.push({
            target: '.name-input',
            title: 'Upload New - Name',
            content: 'What is the name of the certification?',
            placement: 'left',
            disableBeacon: true,
          });
        }

        if (!defaultData?.organization) {
          stepsList.push({
            target: '.issuing-organisation-input',
            title: 'Issuing Organisation',
            content: 'Who issued the certification? Recognized organizations add more value.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!defaultData?.issueDate) {
          stepsList.push({
            target: '.issue-date-input',
            title: 'Issue Date',
            content: 'When was your certification issued?',
            placement: 'left',
            disableBeacon: true,
          });
        }

        if (!defaultData?.expirationDate) {
          stepsList.push({
            target: '.expiry-date-input',
            title: 'Expiry Date',
            content: 'When does your certification expire?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!defaultData?.product) {
          stepsList.push({
            target: '.product-input',
            title: 'Product',
            content: 'Is this certification connected with a specific product?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!defaultData?.modulesIds) {
          stepsList.push({
            target: '.modules-input',
            title: 'Modules',
            content: 'Does this certification cover any specific modules?',
            placement: 'left',
            disableBeacon: true,
          });
        }

        if (!defaultData?.worksIds) {
          stepsList.push({
            target: '.works-input',
            title: 'Types of Work',
            content: 'Are there any specific types of work covered in this certification?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        setSteps(stepsList);

        if (stepsList.length) {
          stepsList.push({
            target: '.submit-button',
            title: 'Add to Profile',
            content: 'Click Add to Profile to save your changes.',
            placement: 'auto',
            disableBeacon: true,
          });

          setRun(true);
        }
      }, 700);
      return () => clearTimeout(timeoutId);
    }
  }, [
    defaultData?.name,
    defaultData?.files,
    defaultData?.expirationDate,
    defaultData?.issueDate,
    defaultData?.modulesIds,
    defaultData?.worksIds,
    defaultData?.organization,
    defaultData?.product,
    showIntro,
  ]);

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: joyrideZIndex,
            },
          },
          customButtonStyles,
        )}
      />
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="certificate-file-input">
          <Controller
            name="file"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  startIcon={<UploadIcon />}
                >
                  Upload file
                  <input
                    id="certificate-file"
                    hidden
                    type="file"
                    accept="image/x-png,image/jpeg,application/pdf"
                    onChange={e => {
                      if (e.currentTarget?.files) {
                        const reader = new FileReader();
                        reader.readAsDataURL(e.currentTarget?.files[0]);
                        field.onChange(e.currentTarget?.files[0]);
                      }
                    }}
                    required
                    readOnly
                  />
                </Button>

                {!!field.value && (
                  <Typography ml={2} my={1}>
                    {field.value.name}
                  </Typography>
                )}
                {!selectedFile && defaultData && !!defaultData?.files.length && (
                  <Typography ml={2}>{defaultData?.files[0].originalName}</Typography>
                )}
              </Grid>
            )}
            rules={{
              required: !defaultData && 'This field is required',
            }}
          />
          {!!errors?.file && (
            <FormHelperText error sx={{ position: 'relative', marginTop: -2, textAlign: 'center' }}>
              {(errors?.file as any).message || 'Incorrect data'}
            </FormHelperText>
          )}
        </FormGroup>
        <Grid container columnSpacing={2}>
          <Grid xs={12} md={6}>
            <FormGroup className="name-input">
              <InputLabel>Name*</InputLabel>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    placeholder="Name"
                    error={!!errors.name}
                    helperText={errors.name?.message || 'Incorrect data'}
                  />
                )}
                rules={{
                  required: 'This field is required',
                  validate: {
                    notEmpty: value => (!value.trim() ? 'Name is required' : undefined),
                  },
                }}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup className="issuing-organisation-input">
              <InputLabel>Issuing organisation*</InputLabel>
              <Controller
                name={`company`}
                defaultValue=""
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: undefined,
                      onInputChange: handleCompanySearch,
                    }}
                    inputProps={{
                      error: errors.company as any,
                      helperText: (errors.company as any)?.message || 'Incorrect data',
                    }}
                    field={field}
                    options={companies || []}
                    placeholder="Issuing organisation"
                    customFilter={customFilter}
                    onOpen={handleComponentOpen}
                    onClose={handleComponentClose}
                    handleChange={handleCreateCompany}
                    isLoading={isCompaniesLoading}
                    disabledMobile
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup className="issue-date-input">
              <InputLabel>Issue Date*</InputLabel>
              <Controller
                name="issueDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={field.value ? dayjs(field.value).toDate() : null}
                    calendarStartDay={1}
                    onCalendarOpen={handleComponentOpen}
                    onCalendarClose={handleComponentClose}
                    customInput={
                      <DateTextInput
                        className="text-custom-input"
                        onReset={() => resetField('issueDate')}
                        icon={<CalendarIcon />}
                        formatDate="YYYY-MM"
                      />
                    }
                    onChange={(date: Date) => {
                      if (expirationDate && dayjs(date).isSameOrBefore(expirationDate, 'month')) {
                        clearErrors('expirationDate');
                      }
                      field?.onChange(date ? dayjs(date).format('YYYY-MM') : '');
                    }}
                    showPopperArrow={false}
                    isClearable
                    placeholderText="YYYY-MM"
                    withPortal={mobile}
                    showMonthYearPicker
                  />
                )}
                rules={{
                  required: 'This field is required',
                  validate: {
                    bothField: date =>
                      !date && expirationDate ? 'Issue Date is required' : undefined,
                    beforeEnd: date =>
                      (expirationDate &&
                        dayjs(date).isAfter(expirationDate, 'month') &&
                        'Issue Date cannot be more than Expiration Date') ||
                      true,
                  },
                }}
              />
              {!!errors?.issueDate && (
                <FormHelperText error sx={{ position: 'relative', marginTop: -2 }}>
                  {(errors?.issueDate as any).message || 'Incorrect data'}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup className="expiry-date-input">
              <InputLabel>Expiration Date</InputLabel>
              <Controller
                name="expirationDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={field.value ? dayjs(field.value).toDate() : null}
                    calendarStartDay={1}
                    onCalendarOpen={handleComponentOpen}
                    onCalendarClose={handleComponentClose}
                    customInput={
                      <DateTextInput
                        className="text-custom-input"
                        onReset={() => resetField('expirationDate')}
                        icon={<CalendarIcon />}
                        formatDate="YYYY-MM"
                      />
                    }
                    onChange={(date: Date) => {
                      if (issueDate && !dayjs(issueDate).isSameOrAfter(date, 'month')) {
                        clearErrors('issueDate');
                      }
                      field?.onChange(date ? dayjs(date).format('YYYY-MM') : '');
                    }}
                    showPopperArrow={false}
                    isClearable
                    placeholderText="YYYY-MM"
                    withPortal={mobile}
                    showMonthYearPicker
                  />
                )}
                rules={{
                  validate: {
                    afterStart: date =>
                      !date ||
                      !issueDate ||
                      dayjs(issueDate).isSameOrBefore(dayjs(date), 'month') ||
                      'Expiration Date cannot be less than Issue Date',
                  },
                }}
              />
              {!!errors?.expirationDate && (
                <FormHelperText error sx={{ position: 'relative', marginTop: -2 }}>
                  {(errors?.expirationDate as any).message || 'Incorrect data'}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup>
              <InputLabel>Credentials ID or URL</InputLabel>
              <Controller
                control={control}
                name="certificateId"
                defaultValue=""
                // defaultValue={defaultData?.certificateId || ""}
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    placeholder="Type Certification ID or URL"
                    error={!!errors.certificateId}
                    helperText={errors.certificateId?.message || 'Incorrect data'}
                  />
                )}
                rules={{
                  maxLength: {
                    message: 'The max count of characters is 240',
                    value: 240,
                  },
                }}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup className="product-input">
              <InputLabel>Product</InputLabel>
              <Controller
                control={control}
                name="product"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      groupBy: option => option.categoryName,
                      ListboxComponent: forwardRef((props: any, ref: any) => (
                        <StyledMasonry
                          ref={ref}
                          component="ul"
                          spacing={2}
                          {...props}
                          columns={1}
                        />
                      )),
                      multiple: undefined,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.product,
                      helperText: (errors.product as any)?.message || 'Incorrect data',
                      endAdornment: field.value ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear selection"
                            onClick={() => {
                              field.onChange({ target: { value: '', name: field.name } });
                              setValue(field.name, '');
                              resetField('modules', { defaultValue: [] });
                              resetField('works', { defaultValue: [] });
                            }}
                            edge="end"
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                    field={field}
                    handleChange={(event, fieldName) => {
                      onTypeaheadItemChange(event, fieldName, setValue);
                      resetField('modules', { defaultValue: [] });
                      resetField('works', { defaultValue: [] });
                    }}
                    options={categoriesWithProductsList || []}
                    onOpen={handleComponentOpen}
                    onClose={handleComponentClose}
                    placeholder="Select a Product"
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup className="modules-input">
              <InputLabel>Modules</InputLabel>
              <Controller
                control={control}
                name="modules"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: true,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.modules,
                      helperText: (errors.modules as any)?.message || 'Incorrect data',
                      sx: {
                        '& .MuiInputBase-inputAdornedStart': {
                          minWidth: '0px !important',
                          padding: 0,
                        },
                      },
                    }}
                    field={field}
                    handleChange={(event, fieldName) => {
                      onTypeaheadItemChange(event, fieldName, setValue);
                    }}
                    isMainTag
                    options={filteredModulesListByProduct || []}
                    placeholder="Select a Module"
                    primaryItem={primaryModule}
                    onOpen={handleComponentOpen}
                    onClose={handleComponentClose}
                    onPrimaryItemChange={id => setValue('primaryModule', id)}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12} md={6}>
            <FormGroup className="works-input">
              <InputLabel>Type of Work</InputLabel>
              <Controller
                control={control}
                name="works"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: true,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.works,
                      helperText: (errors.works as any)?.message || 'Incorrect data',
                      sx: {
                        '& .MuiInputBase-inputAdornedStart': {
                          minWidth: '0px !important',
                          padding: 0,
                        },
                      },
                    }}
                    field={field}
                    isMainTag
                    options={worksListByProduct || []}
                    handleChange={(event, fieldName) => {
                      onTypeaheadItemChange(event, fieldName, setValue);
                      setValue('works', event);
                    }}
                    placeholder="Select a Type of Work"
                    primaryItem={primaryWorkType}
                    onOpen={handleComponentOpen}
                    onClose={handleComponentClose}
                    onPrimaryItemChange={id => setValue('primaryWorkType', id)}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          {defaultData && (
            <Grid container xs={12} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
              <Grid xs={12} md={6}>
                <FormGroup>
                  <LoadingButton
                    color="secondary"
                    loading={isSubmitting}
                    form="hook-form"
                    type="submit"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default CertificateForm;
