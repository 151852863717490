import { createAsyncThunk } from '@reduxjs/toolkit';
import { IChatAssistantMessage } from '../../types/chat-assistant';
import { EChatAssistant } from '../../constants/chat-assistant';
import instance from '../../middlewares/api';
import { OptionalSearchDataDto } from '../../reducers/chat-assistant';

interface IAssistantRequestParams {
  conversation: IChatAssistantMessage[];
  customerId: number;
  filter: OptionalSearchDataDto;
}

interface IAssistantResponseParams {
  message: string;
  filter: OptionalSearchDataDto;
}

const getAssistantResponse = createAsyncThunk<IAssistantResponseParams, IAssistantRequestParams>(
  EChatAssistant.getAssistantResponse,
  async ({ conversation, customerId, filter }) => {
    const response = await instance.post('/ai/chat', { conversation, customerId, filter });
    return response.data;
  },
);

export default getAssistantResponse;
