import React, { FC, useState } from 'react';
import { RefCallBack } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import BootstrapInput from '../BootstrapInput';
import { StyledDrawerWrapperBox, CloseButton, DrawerHeaderContainer } from './styled';

interface ISelect {
  disabled?: boolean;
  label: string;
  labelMenuItem?: string;
  onChange: (event: { target: { name: any; value: any } }) => void;
  options: any[];
  inputRef?: RefCallBack;
  value?: any;
  name?: string;
  sx?: any;
  isClearable?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Select: FC<ISelect> = ({
  label,
  labelMenuItem = 'name',
  options,
  onChange,
  disabled,
  value,
  name,
  sx,
  isClearable,
  onOpen,
  onClose,
  ...params
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpen, setOpen] = useState(false);
  const selectedItem = options.find(item =>
    typeof item.id !== 'undefined' ? item.id === value : item === value,
  );

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  if (mobile) {
    return (
      <Box>
        <BootstrapInput
          dataTest={`select-div-${name}-mob`}
          placeholder={label}
          value={(selectedItem && selectedItem[labelMenuItem]) || selectedItem || ''}
          onClick={() => (disabled ? undefined : handleOpen())}
          disabled={disabled}
          withArrow
          readOnly
          sx={sx}
        />
        <Drawer anchor="bottom" open={isOpen} onClose={handleClose}>
          <StyledDrawerWrapperBox>
            <DrawerHeaderContainer
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              flexWrap="nowrap"
            >
              <CloseButton variant="text" onClick={handleClose} color="secondary">
                <CloseIcon />
              </CloseButton>
              <Typography variant="h4">{label}</Typography>
            </DrawerHeaderContainer>
            {isClearable && (
              <MenuItem id="select-option-none-mob" key="empty-option" value={''}>
                Default
              </MenuItem>
            )}
            {options.map((item: any) => (
              <MenuItem
                data-test={`select-option-${item.id || item}-mob`}
                key={item[labelMenuItem] || item}
                value={item.id || item}
                onClick={() =>
                  onChange({
                    target: {
                      value: item.id || item,
                      name: item[labelMenuItem] || item,
                    },
                  })
                }
                selected={item.id ? item.id === value : item === value}
              >
                {item[labelMenuItem] || item}
              </MenuItem>
            ))}
            <Grid container alignItems="center" justifyContent="center" paddingTop={20}>
              <Button
                data-test={`select-button-${name}-mob`}
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Select
              </Button>
            </Grid>
          </StyledDrawerWrapperBox>
        </Drawer>
      </Box>
    );
  }

  return (
    <MuiSelect
      {...params}
      label={label}
      onChange={onChange}
      input={<BootstrapInput />}
      inputProps={{ sx: { mr: 3 } }}
      disabled={disabled}
      value={value}
      name={name}
      displayEmpty
    >
      {isClearable && (
        <MenuItem data-test="select-option-none-lg" key="empty-option" value={''}>
          Default
        </MenuItem>
      )}
      {options.map((item: any) => (
        <MenuItem
          data-test={`select-option-${item.id || item}-lg`}
          key={item[labelMenuItem] || item}
          value={typeof item.id !== 'undefined' ? item.id : item}
        >
          {item[labelMenuItem] || item}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
