import { Dayjs } from 'dayjs';
import dayjs from '../../utils/dayjs';
import flowRight from 'lodash/fp/flowRight';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import groupBy from 'lodash/fp/groupBy';

const isWeekend = (day: Dayjs) => day.day() === 0 || day.day() === 6;

// export const getWeeksOfMonth = (startYear: number, endYear: number, startMonth: number, endMonth: number) => {
//   const firstDayOfMonth = dayjs().year(startYear).month(startMonth).startOf('month');
//   const lastDayOfMonth = dayjs().year(endYear).month(endMonth).endOf('month');
//
//   const startDayOfWeek = firstDayOfMonth.day();
//   const endDayOfWeek = lastDayOfMonth.day();
//
//   // We calculate the start and end for the first and last weeks of the month
//   const startOfWeek = firstDayOfMonth.subtract(startDayOfWeek, 'day');
//   const endOfWeek = lastDayOfMonth.add(6 - endDayOfWeek, 'day');
//
//   const weeks = [];
//   // We iterate through the weeks and create an array of days
//   for (let currentDay = startOfWeek.clone(); currentDay.isBefore(endOfWeek); currentDay = currentDay.add(1, 'day')) {
//     // We add the array of days of the week to the array of weeks
//     if(!isWeekend(currentDay)) {
//       weeks.push({
//         date: currentDay.format('YYYY-MM-DD'),
//         dayNumber: currentDay.day(),
//         monthNumber: currentDay.month(),
//         isCurrentDay: dayjs().isSame(currentDay, 'day'),
//         weekOfYear: currentDay.week(),
//       })
//     }
//   }
//
//   return weeks;
// }

export const calculatedDate = (currentDate: string, type: string) => {
  const addWeek = (type === 'month' && 8) || 18;
  const startDate = dayjs(currentDate).startOf('week');
  const endDate = startDate.add(addWeek, 'week').endOf('week');

  return {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  };
};

export const getCalendarFromCurrentWeek = (startDate: string, endDate: string, type: string) => {
  // We calculate the start and end for the first and last weeks of the month
  const start = dayjs(startDate).add(1, 'day');
  const end = dayjs(endDate);
  const unit = (type === 'quarter' && 'week') || 'day';

  const weeks = [];
  // We iterate through the weeks and create an array of days
  for (
    let currentDay = start.clone();
    currentDay.isBefore(end);
    currentDay = currentDay.add(1, unit)
  ) {
    // We add the array of days of the week to the array of weeks
    if (!isWeekend(currentDay)) {
      weeks.push({
        date: currentDay.format('YYYY-MM-DD'),
        dayNumber: currentDay.day(),
        month: currentDay.format('YYYY-MM'),
        isCurrentDay: dayjs().isSame(currentDay, 'day'),
        weekOfYear: currentDay.week(),
      });
    }
  }

  return weeks;
};

export const groupByMonth = (weeksOfMonth: any) => {
  return flowRight(
    map(month => ({ list: month, date: get('[0].date')(month) })),
    groupBy('month'),
  )(weeksOfMonth);
};

export const groupByProduct = (agencyExperiences: any) => {
  return flowRight(
    map(month => get('[0].product.company')(month)),
    groupBy('product.companyId'),
  )(agencyExperiences);
};

export const splitDateRangeByWeeks = (startDate: string, endDate: string) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const weeks = [];

  let currentWeekStart = start.startOf('isoWeek');

  while (currentWeekStart.isBefore(end)) {
    const currentWeekEnd = currentWeekStart.endOf('isoWeek').isBefore(end)
      ? currentWeekStart.endOf('isoWeek')
      : end;
    weeks.push({
      from: currentWeekStart.format('YYYY-MM-DD'),
      to: currentWeekEnd.format('YYYY-MM-DD'),
    });
    currentWeekStart = currentWeekEnd.add(1, 'day');
  }

  return weeks;
};

export const getColor = (capacity: number) => {
  switch (true) {
    case capacity >= 10 && capacity < 20:
      return {
        backgroundColor: '#fff9eb',
        color: '#CBB072',
      };
    case capacity >= 20 && capacity < 30:
      return {
        backgroundColor: '#f7ffeb',
        color: '#A1BC79',
      };
    case capacity >= 30 && capacity < 40:
      return {
        backgroundColor: '#ebf7ff',
        color: '#8EBEDF',
      };
    case capacity >= 40:
      return {
        backgroundColor: '#ebfff7',
        color: '#67AA8F',
      };
    default:
      return {
        backgroundColor: 'tertiary.main',
        color: 'secondary.light',
      };
  }
};
