import { createSlice } from '@reduxjs/toolkit';

import map from 'lodash/fp/map';
import cond from 'lodash/fp/cond';
import has from 'lodash/fp/has';
import get from 'lodash/fp/get';

import { IMatchedCard } from '../types/consultant';
import { CommonEntities } from '../types/common';

import { DEFAULT_ENTITY } from '../../models/consts';
import { ITimelineBasis } from '../../models/inner-models';

import searchWithMatching from '../query/order-creation/searchWithMatching';
import getMatchedCardById from '../query/order-creation/getMatchedCardById';

const transformArray = map(
  cond([
    [has('specialist'), (item: IMatchedCard) => ({ ...item, id: get('specialist.id')(item) })],
    [has('agency'), (item: IMatchedCard) => ({ ...item, id: get('agency.id')(item) })],
  ]),
);

export interface OrderCreationState {
  matchedResults: CommonEntities<IMatchedCard[]>;
  matchingScore?: number;
  timeLine: ITimelineBasis | null;
}

const initialState: OrderCreationState = {
  matchedResults: DEFAULT_ENTITY,
  timeLine: null,
};

const { actions, reducer } = createSlice({
  name: 'order-creation',
  initialState,
  reducers: {
    /**
     * TODO: Will be refactored
     * @param state
     */
    resetMatchedResults: state => {
      state.matchedResults = DEFAULT_ENTITY;
    },
  },
  extraReducers: builder =>
    builder
      /**
       *  Matching Consultant and Agency
       */
      .addCase(searchWithMatching.pending, (state, action) => {
        const { meta } = action;

        state.matchedResults = {
          ...state.matchedResults,
          items: [...((meta.arg.offset && state.matchedResults.items) || [])],
          loading: true,
          loaded: false,
        };
      })
      .addCase(searchWithMatching.fulfilled, (state, action) => {
        const {
          payload: { cards, countAll },
        } = action;

        state.matchedResults = {
          countAll,
          items: [...state.matchedResults.items, ...transformArray(cards)],
          hasMore: countAll > state.matchedResults.items.length + cards.length,
          loading: false,
          loaded: true,
        };
      })
      .addCase(getMatchedCardById.fulfilled, (state, action) => {
        const { payload, meta } = action;

        state.matchedResults = {
          items: [
            {
              ...state.matchedResults.items[0],
              cardType: meta.arg.cardType,
              id: payload.id,
              [(meta.arg.cardType === 'agency' && 'agency') || 'specialist']: payload,
            },
          ],
          loading: false,
          loaded: true,
        };
      }),
});

export const { resetMatchedResults } = actions;

export default reducer;
