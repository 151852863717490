import React from 'react';
import uniqueId from 'lodash/fp/uniqueId';

interface IAvatar {
  image?: string | null;
  isBlur?: boolean;
  firstName: string;
  lastName?: string;
  size?: 'xs-small' | 'small' | 'medium' | 'large' | 'pre-large' | 'custom-small';
  icon?: React.ReactNode;
  round?: boolean;
  backgroundColor?: string;
}

import {
  StyledAvatarBase,
  StyledCompanyText,
  StyledRootBase,
  StyledSvg,
  StyledUserText,
} from './styled';

const Avatar: React.FunctionComponent<IAvatar> = ({
  image,
  isBlur,
  firstName,
  lastName,
  size = 'small',
  icon: Icon,
  round,
  backgroundColor,
}) => {
  const unique = uniqueId('avatar_');

  return (
    <StyledRootBase className={size} size={size} round={round}>
      {image && (
        <StyledAvatarBase
          className={size}
          isBlur={isBlur}
          unique={unique}
          size={size}
          src={image}
          title={firstName}
          alt={firstName}
        />
      )}
      {!image && Icon && <StyledCompanyText unique={unique}>{Icon}</StyledCompanyText>}
      {!image && !Icon && (
        <StyledUserText unique={unique} backgroundColor={backgroundColor}>
          {(`${firstName}`.charAt(0) + `${lastName}`.charAt(0)).toUpperCase()}
        </StyledUserText>
      )}
      {!round && (
        <StyledSvg viewBox="0 0 42 47">
          <clipPath id={unique} clipPathUnits="objectBoundingBox">
            <path
              fill="black"
              d="M0,0.306 C0,0.27,0.021,0.236,0.056,0.218 L0.446,0.017 C0.48,0,0.52,0,0.554,0.017 L0.944,0.218 C0.979,0.236,1,0.27,1,0.306 L1,0.702 C1,0.739,0.979,0.773,0.944,0.79 L0.554,0.991 C0.52,1,0.48,1,0.446,0.991 L0.056,0.79 C0.021,0.773,0,0.739,0,0.702 L0,0.306"
            />
          </clipPath>
        </StyledSvg>
      )}
    </StyledRootBase>
  );
};

export default Avatar;
