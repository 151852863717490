import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import { EMobile } from 'store/constants';
import { setNotificationKey } from 'store/reducers/mobile';
import registerUserNotification from './registerUserNotifications';

const updateUserNotificationKey = createAsyncThunk<
  void,
  string,
  { state: RootState; dispatch: Dispatch }
>(EMobile.updateUserNotificationKey, (notificationKey, { getState, dispatch }) => {
  dispatch(setNotificationKey(notificationKey));

  const state = getState();
  const userState = state.user;
  const userId = userState.user?.id;

  if (userId) {
    //@ts-ignore
    dispatch(registerUserNotification(userId));
  }
});

export default updateUserNotificationKey;
