import { IConsultantProducts } from './consultant-models';
import { IUserLanguages } from '../store/types/language';
import {
  CommonEntities,
  CommonEntitiesCount,
  IEntitiesIds,
  ISeniorityDurationLevel,
} from '../store/types/common';
import { IConsultantExperience } from '../store/types/experiences';
import { IDefaultProductAndExperience } from './common-models';

// enums
export enum ELocalStoreKeys {
  ACCESS_TOKEN = 'accessToken',
  IMPERSONATE_TOKEN = 'impersonateToken',
  IMPERSONATE_REFRESH_TOKEN = 'impersonateRefreshToken',
  REFRESH_TOKEN = 'refreshToken',
  ORDER_CREATION = 'orderCreation',
  ORDER_ID = 'orderId',
  GLOBAL_IMPERSONATION = 'globalImpersonation',
  MOBILE_SPLASH = 'mobileSplash',
}

export enum ECookieKeys {
  REGISTRATION_SOURCE = 'wb_source',
}

export enum EAlertTypes {
  NONE = 'none',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum EServerStatusCode {
  EXPIRED_TOKEN = 426,
}

export enum ERole {
  CUSTOMER_ADMIN = 'customer_admin',
  ADMIN = 'admin',
  AGENCY_ADMIN = 'agency_admin',
  CONSULTANT = 'consultant',
  SELF_CUSTOMER = 'self_customer',
  CUSTOMER = 'customer',
  RESOURCE_MANAGER = 'agency_resource_manager',
  LEAD_MANAGER = 'lead_manager',
}

export enum ERoleId {
  CUSTOMER_ADMIN = 4,
  ADMIN = 1,
  AGENCY_ADMIN = 6,
  CONSULTANT = 2,
  SELF_CUSTOMER = 3,
  CUSTOMER = 5,
  RESOURCE_MANAGER = 7,
  LEAD_MANAGER = 8,
}

export enum ELangLevel {
  BASIC = 'basic',
  PROFICIENT = 'proficient',
  FLUENT = 'fluent',
}

export enum EConsultantRegStep {
  PROFILE = 1,
  PRODUCTS = 2,
  PROFICIENCY = 3,
  CONNECT = 4,
}

export enum EConsultantRegProgress {
  NOT_CONSULTANT = 0,
  REGISTRATION_INCOMPLETE = 1,
  CALL_UNSCHEDULED = 2,
  CONSULTANT_UNVETTED = 3,
  MISSING_ACCEPT_TERMS = 4,
  COMPLETE = 5,
}

export enum EConsultantEditingModals {
  NO_MODAL = 0,
  PROFILE = 1,
  PRODUCTS = 2,
  PROFICIENCY = 3,
  AVAILABILITY = 4,
  ADDITIONAL_SKILLS = 6,
  CERTIFICATIONS = 7,
  CERTIFICATE_CREATION_FORM = 8,
}

export enum ECustomerRegStep {
  EXPERTISE = 1,
  TIMELINE = 2,
  SENIORITY = 3,
  RECOMMENDED_EXPERTS = 4,
  CONFIRMATION = 5,
}

export enum EAgencyRegStep {
  PROFILE = 1,
}

export enum EAuthPage {
  LOGIN = '',
  SIGNUP_CONSULTANT = 'signup',
  SIGNUP_CUSTOMER = 'signup-customer',
  CONSULTANT = 'consultant-register',
  SELF_CUSTOMER = 'customer-register',
}

export enum EExperienceActivity {
  ROLE = 'role',
  PROJECT = 'project',
}

export enum ESeniority {
  JUNIOR = 1,
  INTERMEDIATE = 2,
  SENIORITY = 3,
  CUSTOM = 4,
}

export enum ECustomerRegisterModal {
  SIGNUP = 'signup',
  SIGNIN = 'signin',
  VERIFICATION_CODE = 'verification',
  VERIFICATION_SUCCESS = 'verificationSuccess',
  PROFILE = 'profile',
  SAVED_INFO = 'savedInfo',
}

export enum EFeedbackModal {
  GENERAL = 'general',
  DETAIL = 'detail',
}

export enum EChangeTimelineModal {
  FORM = 'form',
  MATCHING = 'matching',
}

export enum EOrderFilter {
  PURCHASE = 'purchaseOrders',
  CURRENT = 'ordersCurrent',
  PURCHASE_ORDERS = 'purchaseOrders',
  CURRENT_PURCHASE_ORDERS = 'currentPurchaseOrders',
  PAST_PURCHASE_ORDERS = 'pastPurchaseOrders',
  PAST_ORDERS = 'pastOrders',
  UNREALIZED_ORDERS = 'unrealizedOrders',
  DRAFT_ORDERS = 'draftOrders',
  FINISH_ORDERS = 'finishOrders',
  CANCEL_ORDERS = 'cancelOrders',
  UNASSIGNED = 'unassignedOrders',
  ASSIGNED = 'assignedOrders',
  NEED_ACTION = 'needActionPreOrders',
  IN_PROGRESS = 'inProgressOrders',
}

export enum ENotificationsType {
  CREATE_ORDER = 'create_order', // 3, 4
  WILL_START_ORDER = 'will_start_order', // 5
  START_ORDER = 'start_order', // 6
  WILL_END_ORDER = 'will_end_order', // 7, 8
  COMPLETE_ORDER = 'complete_order', // 9, 10
  REQUEST_EARLY_COMPLETE_ORDER = 'request_early_complete_order', // 11
  APPROVED_EARLY_COMPLETE_ORDER = 'approved_early_complete_order', // 12
  DECLINE_EARLY_COMPLETE_ORDER = 'decline_early_complete_order', // 13
  APPROVED_FEEDBACK_ORDER = 'approved_feedback_order', // 14
  DECLINE_FEEDBACK_ORDER = 'decline_feedback_order', // 15
  CHANGE_ORDER_EXTEND_HOURS = 'change_order_extend_hours', // 16
  CHANGE_ORDER_ACCEPT = 'change_order_accept', // 17
  CHANGE_ORDER_REJECT = 'change_order_reject', // 18
  CANCEL_ORDER = 'cancel_order', // 19
  CHANGE_ORDER = 'change_order',
  PAID_ORDER_EXTEND_HOURS = 'paid_order_extend_hours',
  CHANGE_ORDER_EXTEND_HOURS_ACCEPT = 'change_order_extend_hours_accept', // 17
  CHANGE_ORDER_EXTEND_HOURS_REJECT = 'change_order_extend_hours_reject', // 18
  CREATE_WEEKLY_BILLING = 'create_weekly_billing',
  SUBMITTED_WEEKLY_BILLING = 'submitted_weekly_billing',
  APPROVED_WEEKLY_BILLING = 'approved_weekly_billing', // not
  CREATE_WEEKLY_BILLING_INVOICE = 'create_weekly_billing_invoice', // customer before pay
  CHANGE_ORDER_UPDATE_TIME_LINE = 'change_order_update_time_line', // updated timeline payment
  // invoice link - button for paying
  PAID_INVOICE = 'paid_invoice', // customer after paying

  END_ORDER = 'end_order',

  // new
  ON_HOLD_ORDER = 'on_hold_order',
  EXPIRED_ORDER = 'expired_order',
  TERMINATED_ORDER = 'terminated_order',
  ADJUSTED_WEEKLY_BILLING = 'adjusted_weekly_billing',
  DISPUTED_WEEKLY_BILLING = 'disputed_weekly_billing',
  // new

  // REQUEST_ORDER = 'request_order',
  // APPROVED_ORDER = 'approved_order',
  // REJECTED_ORDER = 'rejected_order',
  RECEIVE_MESSAGE_CHAT = 'receive_message_chat',

  // draft orders
  DRAFT_ORDER_CREATE = 'draft_order_create',
  DRAFT_ORDER_APPROVE = 'draft_order_approve',
  DRAFT_ORDER_DECLINE = 'draft_order_decline',

  // Preorders
  PRE_ORDER_CREATE = 'pre_order_create',
  PRE_ORDER_PENDING_AGENCY = 'pre_order_pending_agency',
  PRE_ORDER_AGENCY_ACCEPTED = 'pre_order_agency_accepted',
  PRE_ORDER_PENDING_APPROVAL = 'pre_order_pending_approval',
  PRE_ORDER_UPDATED = 'pre_order_updated',
  PRE_ORDER_ACCEPTED = 'pre_order_accepted',
  PRE_ORDER_CONFIRMED = 'pre_order_confirmed',
}

export const ChangeOrderNotificationList = [
  ENotificationsType.COMPLETE_ORDER,
  ENotificationsType.CHANGE_ORDER,
  ENotificationsType.CHANGE_ORDER_EXTEND_HOURS_ACCEPT,
  ENotificationsType.CANCEL_ORDER,
  ENotificationsType.CHANGE_ORDER_UPDATE_TIME_LINE,
  ENotificationsType.END_ORDER,
  ENotificationsType.START_ORDER,
  ENotificationsType.ON_HOLD_ORDER,
  ENotificationsType.EXPIRED_ORDER,
  ENotificationsType.TERMINATED_ORDER,
  ENotificationsType.PAID_ORDER_EXTEND_HOURS,
  ENotificationsType.PAID_INVOICE,
];

export enum ESize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

// order
export enum EChangeAction {
  ACTIVE_ORDER = 'active',
  CHANGE_STATUS = 'change_status',
  CREATE = 'create',
  CREATE_EXTEND_HOURS = 'create_extend_hours',
  ORDER_INVOICE = 'order_invoice',
  ORDER_PAYMENT = 'order_payment',
  READY_TO_START = 'ready_to_start',
  UPDATE_ORDER = 'update_order',
  UPDATE_TIMELINE = 'update_time_line',
  ON_HOLD = 'on_hold',
}

// preorder
export enum EPreorderChangeAction {
  CANCEL = 'cancel_order',
  CREATE = 'create',
  UPDATE_TIMELINE = 'update_time_line',
  CHANGE_STATUS = 'change_status',
  CREATED = 'created',
  ORDER_PAYMENT = 'order_payment',
  ORDER_INVOICE = 'order_invoice',
  SUBMIT_EARLY_COMPLETE = 'submit_early_complite',
  APPROVE_EARLY_COMPLETE = 'approve_early_complite',
  DECLINE_EARLY_COMPLETE = 'decline_early_complite',
  LEAVE_REVIEW = 'leave_review',
  APPROVE_REVIEW = 'approve_review',
  DECLINE_REVIEW = 'decline_review',
  CREATE_EXTEND_HOURS = 'create_extend_hours',
  ACCEPT_EXTEND_HOURS = 'accept_extend_hours',
  DECLINE_EXTEND_HOURS = 'decline_extend_hours',
  SUCCESS_PAID_EXTEND_HOURS = 'success_paid_extend_hours',
  CREATE_WEEKLY_BILLINGS = 'create_weekly_billings',
  SUBMIT_WEEKLY_BILLINGS = 'submit_weekly_billings',
  APPROVE_WEEKLY_BILLINGS = 'approve_weekly_billings',
  DISPUT_WEEKLY_BILLINGS = 'disput_weekly_billings',
  COMPLETE_ORDER_AUTOMATICALLY = 'complete_order_automatically',
  COMPLETE_ORDER = 'complete_order',
  ON_HOLD_ORDER = 'on_hold_order',
  EXPIRED_ORDER = 'expired_order',
  READY_TO_START_ORDER = 'ready_to_start_order',
  TERMINATED_ORDER = 'terminated_order',
  TRIAL_TERMINATED_ORDER = 'trial_terminated_order',
  ACTIVE_ORDER = 'active_order',
  WAIT_PAYMENT_ORDER = 'wait_payment_order',
  SET_PREORDER_BILLING = 'set_preorder_billing',
  UPDATED = 'updated',
  SENT_TO_AGENCY_REVIEW = 'sent_to_agency_review',
  AGENCY_SET_READINESS_DATE = 'agency_set_readiness_date',
  AGENCY_ACCEPTED_PREORDER = 'agency_accepted_preorder',
  AGENCY_DECLINED_PREORDER = 'agency_declined_preorder',
  ALL_AGENCIES_DECLINED_PREORDER = 'all_agencies_declined_preorder',
  WAITING_CUSTOMER_INVITATION = 'waiting_customer_invitation',
  SENT_CUSTOMER_INVITATION = 'sent_customer_invitation',
  CUSTOMER_REGISTERED = 'customer_registered',
  WAITING_CUSTOMER_APPROVAL = 'waiting_customer_approval',
  //AGENCY_SET_TIMELINE
  AGENCY_UPDATED_TIMELINE = 'agency_updated_timeline',
  CUSTOMER_CONFIRMED = 'customer_confirmed',
  CUSTOMER_SELECTED_PURCHASE_ORDER = 'customer_selected_purchase_order',
  CUSTOMER_FINALIZED = 'customer_finalized',
  WAITING_BILLING = 'waiting_billing',
  LEAD_MANAGER_SET_PAID = 'lead_manager_set_paid',
  ORDER_CREATED = 'order_created',
  CANCELLED = 'cancelled',
}

export enum ESorting {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EPreviewOrder {
  FEEDBACK = 'feedback',
  PAY = 'pay',
  EXTEND = 'extend',
  REVIEW = 'review',
  WEEKLY_PAYMENT = 'weekly_payment',
  PAYMENT_REVIEW = 'payment_review',
}

export enum EProgressValue {
  MAX = 5,
  MIN = 0,
}

export type TOrderStatus =
  | 'created'
  | 'expired'
  | 'waiting_for_payment'
  | 'canceled'
  | 'ready_to_start'
  | 'active'
  | 'on_hold'
  | 'completed'
  | 'terminated'
  | 'terminated_during_trial'
  | 'drafts'
  | 'pending_customer'
  | 'in_revision'
  | 'pending_introduction'
  | 'pending_agency'
  | 'pending_approval'
  | 'new';

export enum EPreOrderStatus {
  NEW = 'new',
  IN_REVISION = 'in_revision',
  PENDING_AGENCY = 'pending_agency',
  DECLINED_PREORDER = 'declined_preorder',
  PENDING_INTRODUCTION = 'pending_introduction',
  PENDING_CUSTOMER = 'pending_customer',
  PENDING_BILLING = 'pending_billing',
  PENDING_APPROVAL = 'pending_approval',
  CANCEL_PREORDER = 'cancel_preorder',
  FINALIZE_PAPERWORK = 'finalize_paperwork',
  CONFIRMED = 'confirmed',
}

// constant variable
export const EMAIL_REGEX = /^[\\+\w-\\.]+@([\w-]+\.)+[\w-]+$/;
export const PASSWORD_REGEX = /^\S*$/;
export const NAME_REGEX = /^([a-zA-Z0-9'`\-_]+\s?)*\S$/;
export const PHONE_NUMBER = /^\d{9}$/;
export const GENERIC_PHONE_NUMBER = /^\d{10}$/;
export const ORDER_URL = /^\/orders\/\d+\/?$/;
export const CONSULTANT_URL = /consultant\//;
export const PIPEDRIVE_URL = /pipedrive\//;
export const MAX_AVATAR_IMAGE_SIZE = 5242880; // 5 Mb
export const LIMITS = {
  order: 100,
  chat: 10,
  team: 10,
  consultant: 3,
  feedback: 3,
};
export const TIMELINE_HOURS_NEEDED = [10, 20, 40, 80, 160, 320];

// constant default object
export const DEFAULT_USER_LANGUAGE: IUserLanguages = {
  level: '',
  langId: null,
  language: {},
};

/**
 *  Refactored
 */
export const DEFAULT_ENTITY: CommonEntities<any> = {
  items: [],
  loaded: false,
  loading: false,
};
export const DEFAULT_SENIORITIES: CommonEntitiesCount<any> = {
  items: [],
  loaded: false,
  loading: false,
};

export const DEFAULT_ENTITY_IDS: IEntitiesIds = {
  ids: [],
  hasMore: true,
  loading: true,
  loaded: false,
  sort: ESorting.ASC,
  page: 1,
  countAll: 0,
};

export const DEFAULT_PRODUCTS: IConsultantProducts = {
  id: 0,
  product: undefined,
  productId: 0,
  userId: 0,
};

export const DEFAULT_EXPERIENCE: IConsultantExperience = {
  activities: EExperienceActivity.ROLE,
  description: '',
  startDate: '',
  companyId: 0,
  productId: 0,
  isShowCompanyLogo: false,
  // @ts-ignore
  company: undefined,
};
export const DEFAULT_PRODUCT_EXPERIENCE: IDefaultProductAndExperience = {
  id: 0,
  agencyId: 0,
  productId: 0,
  product: {
    id: 0,
    name: '',
    isActive: true,
    isDeprecated: false,
    categories: [
      {
        id: 1,
        name: '',
        isActive: true,
        isBasic: true,
        isDeprecated: false,
        ProductCategory: {
          productId: 1,
          categoryId: 1,
        },
      },
    ],
    stripeProducts: [],
  },
  workActivities: [],
  workRates: [],
};

export const SeniorityLevelMarks = [
  {
    value: 0,
    label: 'Opt-out',
  },
  {
    value: 25,
    label: 'Experienced',
  },
  {
    value: 50,
    label: 'Advanced',
  },
  {
    value: 75,
    label: 'Expert',
  },
];
export const SeniorityDurationLevel: ISeniorityDurationLevel[] = [
  {
    value: 60,
    label: 'Expert',
    levelId: 3,
  },
  {
    value: 36,
    label: 'Advanced',
    levelId: 2,
  },
  {
    value: 12,
    label: 'Experienced',
    levelId: 1,
  },
  {
    value: 0,
    label: '',
    levelId: 0,
  },
];

export enum EBusinessType {
  LLC = 'llc',
  EIN = 'ein',
  NO_ENTERED = 'not_entered',
}
export enum EWorkEligibilityType {
  ELIGIBLE = 'yes',
  NO_ELIGIBLE = 'not_entered',
}

export const byRole = [
  {
    id: 4,
    name: 'Customer Admin',
  },
  {
    id: 5,
    name: 'Customer',
  },
];

export const byAgencyRoles = [
  {
    id: 2,
    eRole: ERole.CONSULTANT,
    name: 'Regular Specialist',
  },
  {
    id: 6,
    name: 'Agency Admin',
  },
  {
    id: 7,
    eRole: ERole.RESOURCE_MANAGER,
    name: 'Resource Manager',
  },
];

export const byFilterRole = [
  {
    id: null,
    name: 'All Roles',
  },
  {
    id: 4,
    name: 'Customer Admin',
  },
  {
    id: 5,
    name: 'Customer',
  },
];

export const byFilterTeamStatus = [
  {
    id: null,
    name: 'All Statuses',
  },
  {
    id: true,
    name: 'Active',
  },
  {
    id: false,
    name: 'Inactive',
  },
  {
    id: 'null',
    name: 'Pending Invitation',
  },
];

export const byFilterPurchaseStatus = [
  {
    id: null,
    name: 'All Statuses',
  },
  {
    id: 'created',
    name: 'Created',
  },
  {
    id: 'finalized',
    name: 'Finalized',
  },
];

export const availabilityOptions = [
  {
    id: 0,
    name: '0',
  },
  {
    id: 1,
    name: '10',
  },
  {
    id: 2,
    name: '20',
  },
  {
    id: 3,
    name: '30',
  },
  {
    id: 4,
    name: '40',
  },
];

export enum EMatchingResultType {
  AGENCY = 'agency',
  INDIVIDUAL = 'individual',
}

export const SENIORITY_LEVELS = {
  junior: {
    id: 1,
    name: 'Experienced',
  },
  intermediate: {
    id: 2,
    name: 'Advanced',
  },
  senior: {
    id: 3,
    name: 'Expert',
  },
};

export const BILLING_COMPANY = [
  {
    id: 1,
    value: 'lead_source',
    name: 'UKG',
  },
  {
    id: 2,
    value: 'customer',
    name: 'Customer',
  },
];

export const getNameByRoleId = [
  {
    id: 1,
    eRole: ERole.ADMIN,
    name: 'Admin',
  },
  {
    id: 2,
    eRole: ERole.CONSULTANT,
    name: 'Specialist',
  },
  {
    id: 3,
    eRole: ERole.SELF_CUSTOMER,
    name: 'Self Customer',
  },
  {
    id: 4,
    eRole: ERole.CUSTOMER_ADMIN,
    name: 'Customer Admin',
  },
  {
    id: 5,
    eRole: ERole.CUSTOMER,
    name: 'Customer',
  },
  {
    id: 6,
    eRole: ERole.AGENCY_ADMIN,
    name: 'Agency Admin',
  },
  {
    id: 7,
    eRole: ERole.RESOURCE_MANAGER,
    name: 'Resource Manager',
  },
  {
    id: 8,
    eRole: ERole.LEAD_MANAGER,
    name: 'Lead Manager',
  },
];

export const keyStatuses: { [key in EPreOrderStatus]: string[] } = {
  [EPreOrderStatus.NEW]: ['qualifyLead'],
  [EPreOrderStatus.IN_REVISION]: ['qualifyLead'],
  [EPreOrderStatus.DECLINED_PREORDER]: ['qualifyLead'],
  [EPreOrderStatus.PENDING_INTRODUCTION]: ['inviteCustomer', 'finalizeOrder'],
  [EPreOrderStatus.PENDING_BILLING]: ['finalizePaperwork', 'finalizeOrder'],
  [EPreOrderStatus.PENDING_AGENCY]: ['pendingAgency', 'needActions'],
  [EPreOrderStatus.PENDING_CUSTOMER]: ['pendingCustomer', 'finalizeOrder'],
  [EPreOrderStatus.PENDING_APPROVAL]: ['pendingApproval', 'needActions'],
  [EPreOrderStatus.CANCEL_PREORDER]: [],
  [EPreOrderStatus.CONFIRMED]: [],
  [EPreOrderStatus.FINALIZE_PAPERWORK]: [],
};

export const orderTabStatuses: { [key in EOrderFilter]?: TOrderStatus[] } = {
  [EOrderFilter.CURRENT]: ['new', 'on_hold', 'active', 'waiting_for_payment'],
  [EOrderFilter.PAST_ORDERS]: ['completed', 'terminated', 'terminated_during_trial'],
  [EOrderFilter.UNREALIZED_ORDERS]: ['expired', 'canceled'],
};

export const keyStateByRoleName: { [key in ERole]: string } = {
  [ERole.CUSTOMER_ADMIN]: 'customer',
  [ERole.ADMIN]: '',
  [ERole.AGENCY_ADMIN]: '',
  [ERole.CONSULTANT]: 'consultant',
  [ERole.SELF_CUSTOMER]: 'customer',
  [ERole.LEAD_MANAGER]: '',
  [ERole.CUSTOMER]: 'customer',
  [ERole.RESOURCE_MANAGER]: '',
};

export enum EPaymentType {
  SIMPLE = 'simple',
  PURCHASE = 'purchase',
}

export enum EPaymentsKey {
  PREPAYMENT = 'prepayment',
  MONTHLY = 'monthly',
}

export const PaymentTypeAdapter: { [key in EPaymentsKey]: EPaymentType } = {
  [EPaymentsKey.PREPAYMENT]: EPaymentType.SIMPLE,
  [EPaymentsKey.MONTHLY]: EPaymentType.PURCHASE,
};

export enum EPaymentTerms {
  BIWEEKLY = 'biweekly',
  FULL = 'full',
}

export enum EPrepaymentType {
  CARD = 'card',
  ACH = 'ach',
}
export const mobileCookieMaxAge = 2592000;

export enum ECustomRateOptionType {
  PERCENT = 'percent',
  VALUE = 'value',
}
