import { createAsyncThunk } from '@reduxjs/toolkit';

import instance from '../../middlewares/api';
import { EOrder } from '../../constants';
import { IFullOrder } from '../../types/order';
import { IRole } from '../../types/common';
import { ERole } from '../../../models/consts';
import { AxiosError } from 'axios';

interface IOrderByIdProps {
  id: string;
  isDraftOrder?: boolean;
  role: IRole;
  isPurchase?: boolean;
}

interface IRejectedData {
  error: string;
}

const fetchOrderById = createAsyncThunk<IFullOrder, IOrderByIdProps>(
  EOrder.getOrderById,
  async ({ id, isDraftOrder, role, isPurchase }, thunkAPI) => {
    try {
      let url;
      if (role.name === ERole.CUSTOMER_ADMIN || role.name === ERole.CUSTOMER) {
        url = isPurchase ? `/enterprise/orders/${id}` : `/orders/${id}`;
      } else if (isDraftOrder) {
        url = `/draft-orders/${id}`;
      } else if (role.name === ERole.LEAD_MANAGER) {
        url = `/lead-managers/orders/${id}`;
      } else if (role.name === ERole.AGENCY_ADMIN || role.name === ERole.RESOURCE_MANAGER) {
        url = `/agency/orders/${id}`;
      } else {
        url = `/orders/${id}`;
      }

      const { data } = await instance.get(url);

      return data;
    } catch (resError: any) {
      const { response } = resError as AxiosError<any>;
      if (
        response?.status === 404 &&
        !isPurchase &&
        (role.name === ERole.CUSTOMER_ADMIN || role.name === ERole.CUSTOMER)
      ) {
        return thunkAPI.rejectWithValue({ error: 'Check enterprise order' });
      }
      return thunkAPI.rejectWithValue({ error: response?.data?.message });
    }
  },
);

export default fetchOrderById;
