import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiLink from '@mui/material/Link';

import RefreshArrowIcon from '@mui/icons-material/Autorenew';

import { ECustomerRegisterModal, EConsultantRegStep, ERole } from 'models/consts';

import ROUTES from 'router/constants';

import { IBasicUser } from 'store/types/user';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getMeSelector } from 'store/selectors/getUserSelector';

import forgotPassword from 'store/query/user/forgotPassword';
import sendVerifyCode from 'store/query/user/sendVerifyCode';
import verifyCode from 'store/query/user/verifyCode';

import { BootstrapInput } from 'components/CustomFields';

import { StyledActionGroup, StyledGrid, StyledRoot, StyledH1 } from '../styled';

interface IVerificationCodeProps {
  isRegistration?: boolean;
  isModal?: boolean;
  onChangeCustomerStep?: (step: ECustomerRegisterModal) => void;
}

const VerificationCode: FunctionComponent<IVerificationCodeProps> = ({
  isModal,
  onChangeCustomerStep,
  isRegistration,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email] = useState(location.state);

  const user = useAppSelector(getMeSelector) as IBasicUser;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });

  useEffect(() => {
    if (!email && !user) {
      navigate(ROUTES.AUTH);
    }
  }, [email, navigate, user]);

  const onSubmit: SubmitHandler<any> = async ({ code }: any) => {
    await dispatch(verifyCode({ code, ...email }))
      .unwrap()
      .then(({ mailToken }) => {
        if (isModal && onChangeCustomerStep) {
          onChangeCustomerStep(ECustomerRegisterModal.PROFILE);
        } else if (mailToken && !isRegistration) {
          navigate(ROUTES.RESET_PASSWORD, { state: { mailToken } });
        } else {
          navigate(
            user.role?.name === ERole.CONSULTANT
              ? `${ROUTES.CONSULTANT_REGISTRATION}/${EConsultantRegStep.PROFILE}`
              : ROUTES.CUSTOMER_REGISTRATION,
          );
        }
      });
  };

  const refreshSendCode = useCallback(() => {
    if (email) {
      dispatch(forgotPassword(email));
    } else {
      dispatch(sendVerifyCode());
    }
  }, [dispatch, email]);

  return (
    <StyledRoot component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ height: '100%' }}>
        <StyledGrid xs={12} lg={6}>
          <Box flexGrow={{ xs: 1, md: 0 }}>
            {!isModal && (
              <StyledH1 data-test="verify-account-header" component="div">
                <Typography fontSize="inherit" variant="h1">
                  Verify account
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  We just sent you a verification code to your email.
                  <br />
                  Please enter the verification code below.
                </Typography>
              </StyledH1>
            )}
            <FormGroup>
              <InputLabel>Verification code</InputLabel>
              <Controller
                control={control}
                name="code"
                render={({ field: { ref, ...field } }) => (
                  <BootstrapInput
                    {...field}
                    inputRef={ref}
                    error={!!errors.code}
                    placeholder="Enter verification code"
                    helperText={errors.code?.message || 'Incorrect data'}
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </FormGroup>
            <Box sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
              <MuiLink
                data-test="resend-button"
                color="secondary"
                component={Link}
                to={''}
                onClick={refreshSendCode}
                underline="none"
                sx={{ display: 'flex', mb: 5, justifyContent: 'center' }}
              >
                <RefreshArrowIcon />
                <Typography component="span" ml={1}>
                  Resend code
                </Typography>
              </MuiLink>
            </Box>
          </Box>
          <StyledActionGroup>
            <LoadingButton
              data-test="verify-button"
              loading={isSubmitting}
              color="secondary"
              sx={{ mb: 2 }}
              variant="contained"
              disabled={!isValid}
              type="submit"
            >
              Verify
            </LoadingButton>
          </StyledActionGroup>
        </StyledGrid>
      </Grid>
    </StyledRoot>
  );
};

export default VerificationCode;
