import { createSlice } from '@reduxjs/toolkit';
import { IChatAssistantMessage } from '../types/chat-assistant';
import getAssistantResponse from '../query/chat-assistant/getAssistantResponse';
import { ISearchDataDto } from '../types/inner-models';

export type OptionalSearchDataDto = Partial<ISearchDataDto>;
export interface ChatAssistantState {
  chatHistory: IChatAssistantMessage[];
  filter: OptionalSearchDataDto;
}

const initialState: ChatAssistantState = {
  chatHistory: [],
  filter: {},
};

const { actions, reducer } = createSlice({
  name: 'chatAssistant',
  initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      if (payload.message) {
        state.chatHistory.push({ role: 'user', content: payload.message });
      }
    },
  },
  extraReducers: builder =>
    builder.addCase(getAssistantResponse.fulfilled, (state, action) => {
      const {
        payload: { message, filter },
      } = action;
      state.chatHistory = [
        ...state.chatHistory,
        {
          role: 'assistant',
          content: message,
        },
      ];
      state.filter = filter;
    }),
});

export const { addMessage } = actions;

export default reducer;
