import React, { useEffect, useState } from "react";
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

const KeyBoardHeight = () => {

  const [keyBoardHeightPadding, setKeyBoardHeightPadding] = useState(0);

  useEffect(() => {
    const initialize = async () => {
      if (Capacitor.isNativePlatform()) {
        const handleKeyboardDidShow = () => {
          const activeElement = document.activeElement;
          if (activeElement) {
            activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        };

        Keyboard.setAccessoryBarVisible({ isVisible: true });
        Keyboard.setScroll({ isDisabled: false });

        Keyboard.addListener('keyboardWillShow', info => {
          setKeyBoardHeightPadding(info.keyboardHeight);
        });
        Keyboard.addListener('keyboardWillHide', () => {
          setKeyBoardHeightPadding(0);
        });
        Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow);
      }
    };

    initialize();
  }, []);

  return { keyBoardHeightPadding };
};

export default KeyBoardHeight;