import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EOrderCreation } from '../../constants';

const getMatchedCardById = createAsyncThunk(
  EOrderCreation.getMatchedCardById,
  async (payload: { cardType: string; id: number | null }) => {
    let url = `/agency/${payload.id}`;

    if (payload.cardType !== 'agency') {
      url = `/consultant/${payload.id}`;
    }
    const { data } = await instance.get(url);
    return data;
  },
);

export default getMatchedCardById;
