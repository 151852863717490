import React, { useState, useEffect } from 'react';
import useDebounce from './useDebounce';

import { useAppDispatch } from 'store/hooks';
import { ICompany } from 'store/types/company';

import searchCompanyByName from 'store/query/company/searchCompanyByName';

/**
 *  TODO: Will be refactor
 */
// To check if company name available for creation by customer
const useSearchCompanyByName = ({ availableForCustomer }: { availableForCustomer?: boolean }) => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<ICompany[] | []>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      dispatch(searchCompanyByName({ name: debouncedSearchTerm, availableForCustomer }))
        .unwrap()
        .then(payload => {
          setCompanies(payload);
        })
        .catch(error => {
          setCompanies([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCompanies([]);
    }
  }, [debouncedSearchTerm, dispatch, availableForCustomer]);

  useEffect(() => {
    return () => {
      setSearchTerm('');
      setLoading(false);
      setCompanies([]);
    };
  }, []);

  return { setSearchTerm, companies, isLoading, setLoading };
};

export default useSearchCompanyByName;
