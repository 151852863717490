import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { ConsultantOldState } from 'store/reducers/consultantOld';

import getOr from 'lodash/fp/getOr';
import { ConsultantState } from '../reducers/consultant';

/**
 * TODO: Will be refactored
 */
export const feedbacksConsultantSelector = (id: number) =>
  createSelector(
    (state: DefaultRootState) => state.consultantOld,
    ({ feedbacks }: ConsultantOldState) => (feedbacks && feedbacks[id]) || [],
  );
export const feedbacksLoadedSelector = createSelector(
  (state: DefaultRootState) => state.consultantOld,
  ({ loadedFeedbacks }: ConsultantOldState) => loadedFeedbacks,
);
export const hasMoreFeedbacksSelector = createSelector(
  (state: DefaultRootState) => state.consultantOld,
  ({ hasMoreFeedbacks }: ConsultantOldState) => hasMoreFeedbacks,
);

const capacitiesByRange = (state: DefaultRootState) => state.consultantOld.capacities;
const calendarByRange = (state: DefaultRootState) => state.consultantOld.calendar;

export const capacitiesSelector = (key: string) =>
  createSelector(capacitiesByRange, getOr([])(key));

export const calendarSelector = (key: string) => createSelector(calendarByRange, getOr([])(key));

/**
 *  Refactored 12.23
 */
const ConsultantsState = (state: DefaultRootState) => state.consultant;
const ConsultantsById = (state: DefaultRootState) => state.consultant.consultantById;

export const getFullConsultantSelector = (key: string | number) =>
  createSelector(ConsultantsState, ({ consultantById }) => consultantById[key]);

export const getBasicConsultantSelector = (key: string | number) =>
  createSelector(ConsultantsById, getOr(undefined)([key, 'consultant']));

export const productsSummarySelector = (key: string | number) =>
  createSelector(ConsultantsById, getOr([])([key, 'productExperienceFull']));

export const productsSummaryLoadingSelector = (key: string | number) =>
  createSelector(ConsultantsById, getOr([])([key, 'loading']));
