import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECompany } from 'store/constants';

const checkCompanyByName = createAsyncThunk(
  ECompany.checkCompanyByName,
  async ({
    name,
    isAgency,
    availableForCustomer,
  }: {
    name: string;
    isAgency?: boolean;
    availableForCustomer?: boolean;
  }) => {
    let query = `search=${name}`;
    if (isAgency) {
      query = query + `&isAgency=${isAgency}`;
    }
    if (availableForCustomer) {
      query = query + `&availableForCustomer=${availableForCustomer}`;
    }
    const { data } = await instance.get(`companies/check?${query}`);
    return data;
  },
);

export default checkCompanyByName;
