import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { ECompany } from '../../constants';

const searchCompanyByName = createAsyncThunk(
  ECompany.searchCompanyByName,
  async ({ name, availableForCustomer }: { name: string; availableForCustomer?: boolean }) => {
    let query = `search=${name}`;
    if (availableForCustomer) {
      query = query + `&availableForCustomer=${availableForCustomer}`;
    }
    const { data } = await instance.get(`companies/search/?${query}`);
    return data;
  },
);

export default searchCompanyByName;
