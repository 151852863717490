import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import cloneDeep from 'lodash/fp/cloneDeep';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/orderBy';

import Box from '@mui/material/Box';
import Button, { buttonClasses } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, useTheme } from '@mui/material/styles';

import PlusIcon from 'styles/icons/PlusIcon';

import { DEFAULT_EXPERIENCE } from 'models/consts';
import { IProduct } from 'store/types/categoryProducts';
import { IBasicConsultant } from 'store/types/consultant';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import updateAction from 'store/actions/updateAction';
import deleteSubExperience from 'store/query/consultant/deleteSubExperience';
import fetchProductPricesWithoutFee from 'store/query/common/fetchProductPricesWithoutFee';

import { productPricesSelector } from 'store/selectors/getCommonSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import ExperiencesItem from './ExperiencesItem';
import ExperienceForm from './ExperienceForm';
import ExperienceLevel from '../../ExperienceLevel';
import { Step } from 'react-joyride';
import { useMediaQuery } from '@mui/material';
import useDeviceInfo from '../../../../hooks/useDeviceInfo';

const StyledLink = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    display: 'inline-flex',
    height: 'auto',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-0.5),
    paddingX: 3,
    whiteSpace: 'nowrap',
    fontSize: '.875rem',
    backgroundColor: 'common.white',
    [`& .${buttonClasses.startIcon}`]: {
      mr: 0.5,
    },
    '&:hover': {
      backgroundColor: 'common.white',
      color: 'primary.main',
    },
    '&:disabled': {
      color: '#d4d4d4',
      backgroundColor: 'common.white',
    },
  }),
);

interface IExperiencesContainer {
  indexProduct: number;
  product: IProduct;
  userId: string | number;
  productCount?: number;
  onCopyExperience?: (productId: number) => void;
  showExpertiseForm?: boolean;
  isModal?: boolean;
  showIntro?: boolean;
  setSteps?: React.Dispatch<React.SetStateAction<Step[]>>;
  setRun?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExperiencesContainer: FC<IExperiencesContainer> = ({
  indexProduct,
  product,
  userId,
  productCount = 0,
  onCopyExperience,
  showExpertiseForm = false,
  isModal = false,
  setSteps,
  setRun,
  showIntro,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const productPrices = useAppSelector(productPricesSelector(product.id));
  const consultant = useAppSelector(getBasicConsultantSelector(userId)) as IBasicConsultant;

  const [experienceItem, setExperienceItem] = useState<number | null>(showExpertiseForm ? 0 : null);
  const {
    actions,
    state: { experiences },
  } = useStateMachine({ updateAction });
  const {
    control,
    formState: { isValid },
    getValues,
    watch,
    trigger,
  } = useFormContext<FieldValues>();
  const { fields, prepend, remove, replace } = useFieldArray({
    control,
    name: `experiences.${indexProduct}.experiences`,
    keyName: '_id',
  });
  const watchFieldArray = watch(`experiences.${indexProduct}.experiences`, []);
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...(watchFieldArray && watchFieldArray[index]),
  }));

  const handleClick = (value: number | null) => {
    setExperienceItem(value);
  };
  const handleAddExperiences = () => {
    prepend({
      ...DEFAULT_EXPERIENCE,
      productId: product.id,
      isCurrentWork: true,
      isNew: true,
    });
    handleClick(0);
  };

  const handleEditExperiences = (index: number) => {
    handleClick(index);
  };

  const handleCopyExperiences = () => {
    if (onCopyExperience) {
      if (experienceItem !== null) {
        const currentExperience = controlledFields[experienceItem];
        if (currentExperience && currentExperience.isNew) {
          remove(experienceItem);
        }
      }
      handleClick(null);
      onCopyExperience(product.id);
    }
  };

  const handleRemoveExperiences = (id: number | undefined, index: number, indexProduct: number) => {
    if (id) {
      dispatch(
        deleteSubExperience({
          ids: [id as number],
          index,
          indexProduct,
          userId,
        }),
      );
    }
    remove(index);
    // actions.updateAction(getValues());
    handleClick(null);

    if (controlledFields.length <= 1 && indexProduct == 0) {
      handleAddExperiences();
    }
  };

  const handleSaveExperiences = (index: number) => {
    const values = cloneDeep(getValues());
    const experiences = map((p: any) => ({
      ...p,
      experiences: sortBy(
        'endDate',
        'desc',
      )(
        map((experience: any) => ({
          ...experience,
        }))(p.experiences),
      ),
    }))(values.experiences);

    actions.updateAction({ ...values, experiences });
    replace(experiences[index].experiences);
    handleClick(null);
  };

  const getProductsPrices = useCallback(() => {
    if (product.id) {
      dispatch(fetchProductPricesWithoutFee(product.id));
    }
  }, [dispatch, product.id]);

  useEffect(() => {
    getProductsPrices();
  }, [getProductsPrices]);

  return (
    <>
      <ExperienceLevel periods={controlledFields} productPrices={productPrices} />
      <Grid container>
        <Grid xs>
          <Divider />
        </Grid>
        <Grid xs="auto">
          <StyledLink
            color="secondary"
            disabled={!isValid || experienceItem !== null}
            onClick={handleAddExperiences}
            startIcon={<PlusIcon height={20} width={20} />}
            disableRipple
            variant="outlined"
            data-test="add-exp"
          >
            Add new
          </StyledLink>
          {productCount > 1 && (
            <StyledLink
              color="secondary"
              disabled={false}
              onClick={handleCopyExperiences}
              startIcon={<PlusIcon height={20} width={20} />}
              disableRipple
              variant="outlined"
              data-test="copy-exp"
            >
              {mobileScreen ? 'Copy Existing' : 'Copy From Another Product'}
            </StyledLink>
          )}
        </Grid>
      </Grid>
      <Box display="flex" mr={{ md: 11.25 }}>
        <Box flexGrow={1}>
          {controlledFields.map(
            (item, index) =>
              (experienceItem === index && (
                <ExperienceForm
                  key={item?._id}
                  item={item}
                  disabledForAgency={consultant?.agencyId === item.companyId}
                  name={`experiences.${indexProduct}.experiences.${index}`}
                  onRemoveExperiences={() => handleRemoveExperiences(item?.id, index, indexProduct)}
                  onSaveExperiences={() => handleSaveExperiences(indexProduct)}
                  setSteps={setSteps}
                  setRun={setRun}
                  showIntro={showIntro}
                />
              )) || (
                <ExperiencesItem
                  key={item._id}
                  item={item}
                  index={index}
                  onEditExperiences={() => handleEditExperiences(index)}
                  onRemoveExperiences={() => handleRemoveExperiences(item?.id, index, indexProduct)}
                  disabledForAgency={consultant?.agencyId === item.companyId}
                />
              ),
          )}
        </Box>
      </Box>
    </>
  );
};

export default ExperiencesContainer;
