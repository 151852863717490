import { EPreOrderStatus, TOrderStatus } from 'models/consts';

export const dollarFormat = (amount: number) => amount.toLocaleString('en-US');

export const rateFormat = (rate: number) => {
  return rate.toFixed(1);
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const snakeCaseToTextFormatter = (text: string) => {
  return capitalizeFirstLetter(text.replaceAll('_', ' '));
};

export const statusText = (status: TOrderStatus | EPreOrderStatus) => {
  if (status === 'waiting_for_payment') {
    return 'Waiting For Payment';
  }
  if (status === 'ready_to_start') {
    return 'Ready To Start';
  }
  if (status === 'on_hold') {
    return 'On Hold';
  }
  if (status === 'terminated_during_trial') {
    return 'Terminated During Trial';
  }
  if (status === 'canceled') {
    return 'Cancelled';
  }
  return capitalizeFirstLetter(status.replace('_', ' '));
};

export const emailHelper = (email: string) => {
  return email.replace(/(.)(.*)(@.*)/gm, (_, p1, p2, p3) => p1 + p2.replace(/./g, '*') + p3);
};

export const formatNumber = (numb: number) => parseFloat(numb.toFixed(1));

export const ellipsize = (text: string, length: number) =>
  text.length > length ? `${text.slice(0, length)}...` : text;

export const formatUserName = (firstName: string, lastName: string) => {
  return firstName.charAt(0) + '. ' + lastName.charAt(0) + '.';
};
