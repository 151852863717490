import React, { forwardRef, useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';

import find from 'lodash/fp/find';
import filter from 'lodash/fp/filter';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledH1Mobile } from 'views/Auth/styled';

import { useAppSelector } from 'store/hooks';
import { onTypeaheadItemChange } from 'utils/orderCreationHelpers';

import updateAction from 'store/actions/updateAction';

import { ICustomerOrderFlowProps } from 'store/types/inner-models';
import {
  IExtendCategoryWithProducts,
  IModuleOfProduct,
  IWorkTypesOfProduct,
} from 'store/types/categoryProducts';
import { IProduct } from 'store/types/categoryProducts';
import { IPurchaseOrder } from 'store/types/enterprise';
import { IBasicUser } from 'store/types/user';

import { getMeSelector, hasVerifiedCustomerSelector } from 'store/selectors/getUserSelector';
import {
  modulesByProductSelector,
  workTypesByProductSelector,
} from 'store/selectors/getCommonSelector';

import HeaderFlow from '../../Header/HeaderFlow';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import CustomTooltip from 'components/CustomTooltip';
import { ToastService } from 'services';
import ToastMessage from 'components/Toast/ToastMessage';
import { StyledMasonry } from '../../CustomFields/CustomAutocomplete/styled';
import useSafeAreaView from 'components/SafeAreaView';

interface IExpertiseProps extends ICustomerOrderFlowProps {
  handleSignup: () => void;
  modulesList: IModuleOfProduct[];
  productList: IExtendCategoryWithProducts[];
  worksList: IWorkTypesOfProduct[];
}

interface IExpertiseForm {
  modules: IModuleOfProduct[];
  product?: IProduct;
  primaryModule: number;
  primaryWorkType: number;
  purchaseOrder?: IPurchaseOrder;
  works: IWorkTypesOfProduct[];
}

const Expertise: React.FunctionComponent<IExpertiseProps> = ({
  isModal,
  handleSignup,
  modulesList,
  productList,
  worksList,
}) => {
  const location = useLocation();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { safeAreaPadding } = useSafeAreaView();

  const user = useAppSelector(getMeSelector) as IBasicUser;

  const navigate = useNavigate();

  const {
    actions,
    state: { searchParams },
  } = useStateMachine({ updateAction });

  const {
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    resetField,
    watch,
  } = useForm<IExpertiseForm>({
    mode: 'onChange',
    defaultValues: {
      modules:
        filter(
          (item: IModuleOfProduct) =>
            searchParams?.moduleIds?.length && searchParams?.moduleIds?.includes(item.id),
        )(modulesList) || undefined,
      product: find({ id: searchParams?.productId } as IProduct)(productList) || undefined,
      primaryModule: searchParams?.primaryModule,
      primaryWorkType: searchParams?.primaryWorkType,
      purchaseOrder: location.state || undefined,
      works:
        filter(
          (item: IWorkTypesOfProduct) =>
            searchParams?.workIds?.length && searchParams?.workIds?.includes(item.id),
        )(worksList) || undefined,
    },
  });

  const [product, primaryModule, primaryWorkType] = watch([
    'product',
    'primaryModule',
    'primaryWorkType',
  ]);

  const modulesByProductList = useAppSelector(
    modulesByProductSelector(product?.id || searchParams?.productId),
  );
  const worksByProductList = useAppSelector(
    workTypesByProductSelector(product?.id || searchParams?.productId),
  );

  const hasVerifiedCustomer = useAppSelector(hasVerifiedCustomerSelector);

  useEffect(() => {
    if (user && user.role?.id === 2) {
      ToastService.error(
        ToastMessage({ title: 'Alert', body: 'As a specialist you can not create an order.' }),
        { autoClose: false },
      );
    }
  }, [user]);

  useEffect(() => {
    actions.updateAction({ searchParams: {} });
  }, [actions]);

  const onSubmit: SubmitHandler<any> = values => {
    if (!hasVerifiedCustomer) {
      handleSignup();
    } else {
      const params = {
        workIds:
          (product?.isBasic && values.works.map((item: any) => item.id).toString()) ||
          worksByProductList[0].id.toString(),
        moduleIds: values.modules.map((item: any) => item.id).toString(),
        primaryModule: primaryModule,
        primaryWorkType:
          (product?.isBasic && primaryWorkType) || worksByProductList[0].id.toString(),
        productId: values.product?.id,
      };

      navigate({
        pathname: '/order-flow/2',
        search: createSearchParams(params as any).toString(),
      });
    }
  };

  const companyNameFilter = (options: any, param: String) => {
    const inputLower = param.toLowerCase();

    if (inputLower) {
      return options.filter((option: IExtendCategoryWithProducts) => {
        // Check if the option name includes the input value
        const nameMatch = option.name.toLowerCase().includes(inputLower);
        // Check if the option has a company and its name includes the input value
        const companyMatch = option.company?.name?.toLowerCase().includes(inputLower);

        return nameMatch || companyMatch;
      });
    }
  };

  return (
    <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
      {!isModal && (
        <>
          <HeaderFlow
            id="expertise-header"
            disableBtn={!isValid || user?.role?.id === 2}
            btn="Next Step"
            title="Expertise Needed"
            subTitle=""
          />
          <StyledH1Mobile component="div">
            <Typography id="expertise-header" component="h1" variant="h4" mb={2}>
              Expertise Needed
            </Typography>
          </StyledH1Mobile>
        </>
      )}
      <Grid container spacing={2}>
        <Grid sx={{ textAlign: { xs: 'left', md: 'center' } }} xs={12}>
          <FormLabel>
            Which software product or general role best matches the expertise you need for your
            project?
          </FormLabel>
        </Grid>
        <Grid xs={12} md={10} mdOffset={1} lg={6} lgOffset={3}>
          <Controller
            control={control}
            name="product"
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  groupBy: option => option.categoryName,
                  ListboxComponent: forwardRef((props: any, ref: any) => (
                    <StyledMasonry
                      ref={ref}
                      columns={mobile ? 1 : 2}
                      component="ul"
                      spacing={2}
                      {...props}
                    />
                  )),
                  multiple: undefined,
                }}
                inputProps={{ ...(tablet && { readOnly: true }) }}
                customFilter={(options, inputValue) => companyNameFilter(options, inputValue)}
                field={field}
                handleChange={(event, fieldName) => {
                  onTypeaheadItemChange(event, fieldName, setValue);
                  resetField('modules', { defaultValue: [] });
                  resetField('works', { defaultValue: [] });
                }}
                options={productList || []}
                placeholder="Select a Product"
                mobilesx={{
                  marginTop: safeAreaPadding.top,
                }}
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>
        {product?.id && (
          <>
            <Grid xs={12} hidden={!product?.isBasic}>
              <FormLabel
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'start', md: 'center' },
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div>Which type of work are you looking for?</div>
              </FormLabel>
            </Grid>
            <Grid xs={12} md={10} mdOffset={1} lg={6} lgOffset={3} hidden={!product?.isBasic}>
              <Controller
                control={control}
                name="works"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: true,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.works,
                      helperText: (errors.works as any)?.message || 'Incorrect data',
                      sx: {
                        '& .MuiInputBase-inputAdornedStart': {
                          minWidth: '0px !important',
                          padding: 0,
                        },
                      },
                    }}
                    field={field}
                    isMainTag
                    options={worksByProductList || worksList}
                    handleChange={(event, fieldName) =>
                      onTypeaheadItemChange(event, fieldName, setValue)
                    }
                    placeholder="Select Types of Work"
                    primaryItem={primaryWorkType}
                    onPrimaryItemChange={id => setValue('primaryWorkType', id)}
                    mobilesx={{
                      marginTop: 0,
                    }}
                  />
                )}
                rules={{ required: (product?.isBasic && 'This field is required') || undefined }}
              />
            </Grid>
            <Grid xs={12}>
              <FormLabel
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'start', md: 'center' },
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <div>
                  {(product?.isBasic && 'Which modules do you need help with?') ||
                    'Which skills do you need help with?'}
                </div>
              </FormLabel>
            </Grid>
            <Grid xs={12} md={10} mdOffset={1} lg={6} lgOffset={3}>
              <Controller
                control={control}
                name="modules"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: true,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.modules,
                      helperText: (errors.modules as any)?.message || 'Incorrect data',
                      sx: {
                        '& .MuiInputBase-inputAdornedStart': {
                          minWidth: '0px !important',
                          padding: 0,
                        },
                      },
                    }}
                    field={field}
                    handleChange={(event, fieldName) =>
                      onTypeaheadItemChange(event, fieldName, setValue)
                    }
                    isMainTag
                    options={modulesByProductList || modulesList}
                    placeholder={`Select ${(product?.isBasic && 'Modules') || 'Skills'}`}
                    primaryItem={primaryModule}
                    onPrimaryItemChange={id => setValue('primaryModule', id)}
                    mobilesx={{
                      marginTop: 0,
                    }}
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </Grid>
          </>
        )}
      </Grid>
      {!isModal && (
        <Box
          mb={{ xs: 3, md: 6 }}
          mt={5}
          display={{ xs: 'flex', md: 'none' }}
          justifyContent="center"
        >
          <Button
            data-test="mobile-flow-button"
            id="mobile-flow-button"
            form="hook-form"
            disabled={!isValid}
            type="submit"
            variant="contained"
          >
            Next Step
          </Button>
        </Box>
      )}
    </form>
  );
};

export default Expertise;
