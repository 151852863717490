import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'utils/dayjs';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ROUTES from 'router/constants';
import { daysDiff } from 'utils/dateFormatter';

import { useAppDispatch } from 'store/hooks';

import { ENotificationsType, EPreviewOrder, ERole } from 'models/consts';
import { IBasicOrders, IWeeklyBillings } from 'store/types/order';
import { INotifications } from 'store/types/common';

import acceptChanges from 'store/query/order/acceptChanges';
import approveOrderComplete from 'store/query/order/approveOrderComplete';
import declineOrderComplete from 'store/query/order/declineOrderComplete';
import markActionedNotification from 'store/query/notification/markActionedNotification';
import rejectChanges from 'store/query/order/rejectChanges';

import { StyledAction, StyledLink } from 'components/Notification/styled';
import { IBasicPreOrder } from '../store/types/preOrder';

interface INotifyMsgProps {
  notification: INotifications;
  notificationData: any;
  close: () => void;
  isConsultant: boolean;
  isAgency: boolean;
  roleName: ERole;
}

const useNotifyMsg: FC<INotifyMsgProps> = ({
  notification,
  notificationData,
  close,
  isConsultant,
  isAgency,
  roleName,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { notificationType, createdAt, isAction, id, order: orderReal } = notification;

  const order = orderReal || (notificationData.order as IBasicOrders);
  const draftOrder = notificationData.draftOrder as IBasicOrders;
  const preOrder = notificationData.preOrder as IBasicPreOrder;
  const weeklyBilling = notificationData.weeklyBilling as IWeeklyBillings;

  const orderUrl = `${ROUTES.ORDER}/${order?.id}/${order?.purchaseOrderId ? 'enterprise/' : ''}`;

  const startDate = order?.timeLine?.startDate;
  const endDate = order?.timeLine?.endDate;

  const diffStart = daysDiff(startDate, createdAt);
  const diffEnd = daysDiff(endDate, createdAt);

  const msgDom = notificationData?.msgDom;

  const acceptExtension = () => {
    dispatch(
      acceptChanges({
        orderId: order.id,
        extendHoursId: notificationData.extendHour.id,
        hours: notificationData.extendHour.hours,
        notificationId: id,
      }),
    );
  };

  const declineExtension = () => {
    dispatch(
      rejectChanges({
        orderId: order.id,
        extendHoursId: notificationData.extendHour.id,
        hours: notificationData.extendHour.hours,
        notificationId: id,
      }),
    );
  };

  const goToOrder = (preview: EPreviewOrder) => {
    dispatch(markActionedNotification({ ids: [id] }));
    if (preview === EPreviewOrder.REVIEW) {
      navigate(`${orderUrl}?weekly_id=${weeklyBilling.id}`);
    } else if (preview === EPreviewOrder.PAYMENT_REVIEW) {
      navigate(`${orderUrl}?weekly_id=${weeklyBilling.id}&status=pending`);
    } else {
      navigate(
        `${orderUrl}?preview=${
          preview !== EPreviewOrder.PAY
            ? preview
            : preview + '&extend-id=' + notificationData?.extendHour?.id
        }`,
      );
    }
    close();
  };

  const goToChat = (orderId?: string) => {
    orderId && navigate(`${ROUTES.CHAT}/${orderId}`);
    close();
  };

  const approveComplete = () => {
    dispatch(
      approveOrderComplete({
        order: {
          ids: [order.id],
        },
        notification: {
          ids: [id],
        },
      }),
    );
  };

  const declineComplete = () => {
    dispatch(
      declineOrderComplete({
        order: {
          ids: [order.id],
        },
        notification: {
          ids: [id],
        },
      }),
    );
  };

  const msg = useMemo(() => {
    switch (notificationType) {
      // Created
      // Order has been created, consultant is selected and order is paid for
      case ENotificationsType.CREATE_ORDER: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: { __html: msgDom } } : {})}
            >
              {msgDom ? undefined : (
                <>
                  Congratulations on your new order <b>{` #${order.hashCode}`}</b>! You have been
                  engaged by {`${order.customer?.firstName} ${order.customer?.lastName}`} for{' '}
                  {order.timeLine.allHours} hours starting on{' '}
                  {dayjs(order.timeLine.startDate).format('DD MMM, YYYY')}.
                </>
              )}
            </StyledLink>
          );
        }
        if ([ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  New order <b>{` #${order.hashCode} `}</b> has been created!
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                Congratulations! Your order <b>{` #${order.hashCode} `}</b> has been created!
              </>
            )}
          </StyledLink>
        );
      }
      // Order Starting Soon
      // When Order Start Date is in 5 and 2 days
      case ENotificationsType.WILL_START_ORDER:
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                The order <b>{` #${order.hashCode} `}</b> is scheduled to start in {diffStart + 1}{' '}
                days.
              </>
            )}
          </StyledLink>
        );
      // Order Started
      // When Order Start Date is Today
      case ENotificationsType.START_ORDER: {
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                The order <b>{` #${order.hashCode} `}</b> starts today.
              </>
            )}
          </StyledLink>
        );
      }
      // Order Ending Soon
      // When Order End Date is in 5 and 2 days
      case ENotificationsType.WILL_END_ORDER: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> is ending in {diffEnd + 1} days.
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <>
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> is ending in {diffEnd + 1} days.
                </>
              )}
            </StyledLink>
            {!isAction && (
              <StyledAction>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                  onClick={() => goToOrder(EPreviewOrder.EXTEND)}
                >
                  Extend Order
                </Button>
              </StyledAction>
            )}
          </>
        );
      }
      // Order Ended (End Date)
      // When Order End Date is Today
      case ENotificationsType.COMPLETE_ORDER: {
        if (isConsultant) {
          return (
            <StyledLink to={`${orderUrl}`} onClick={() => close()}>
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> has been completed.
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <>
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> has been completed.
                </>
              )}
            </StyledLink>
            {!isAction && (
              <StyledAction>
                Leave a review for {`${order.worker?.firstName} ${order.worker?.lastName}`}
                <Button
                  onClick={() => goToOrder(EPreviewOrder.FEEDBACK)}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                >
                  Leave Review
                </Button>
              </StyledAction>
            )}
          </>
        );
      }
      // Order Ended (Early End)
      // When consultant ended the order before the order End Date
      case ENotificationsType.REQUEST_EARLY_COMPLETE_ORDER:
        return (
          <>
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> has been marked as completed by{' '}
                  {`${order.worker?.firstName} ${order.worker?.lastName}`}.
                </>
              )}
            </StyledLink>
            {!isAction && (
              <StyledAction>
                Please confirm
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 1 }}
                    onClick={approveComplete}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={declineComplete}
                  >
                    Decline
                  </Button>
                </div>
              </StyledAction>
            )}
          </>
        );
      // When Customer Confirms Early End of Order
      case ENotificationsType.APPROVED_EARLY_COMPLETE_ORDER:
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                The order <b>{` #${order.hashCode} `}</b> has been confirmed as completed.
              </>
            )}
          </StyledLink>
        );
      // When Customer Declines Early End of Order
      case ENotificationsType.DECLINE_EARLY_COMPLETE_ORDER:
        return (
          <>
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> has not been confirmed as completed.
                </>
              )}
            </StyledLink>
            <StyledAction>
              Please coordinate with {`${order.customer?.firstName} ${order.customer?.lastName}`} to
              understand what might be outstanding.
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 1, mr: 'auto' }}
                onClick={() => goToChat(order.chat.id)}
              >
                Send Message
              </Button>
            </StyledAction>
          </>
        );
      // When admin approves customer's review.
      // It will be availble on the consultant's profile
      case ENotificationsType.APPROVED_FEEDBACK_ORDER:
        if (isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  You just got a {order.feedbacks ? order.feedbacks[0]?.totalRate : '0'} star review
                  for the order <b>{` #${order.hashCode} `}</b>!
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                Review to the order <b>{` #${order.hashCode} `}</b> have been accepted by admin.
              </>
            )}
          </StyledLink>
        );
      // When admin declines customer's review.
      // It will be availble to leave once more on the order's page
      case ENotificationsType.DECLINE_FEEDBACK_ORDER:
        if (!isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  Review to the order <b>{` #${order.hashCode} `}</b> have not been accepted by
                  admin. Please leave the new review for the{' '}
                  {`${order.worker?.firstName} ${order.worker?.lastName}`}.
                </>
              )}
            </StyledLink>
          );
        }
        return null;
      // When the Customer extends the number of hours in the order
      case ENotificationsType.CHANGE_ORDER_EXTEND_HOURS:
        if (isConsultant || [ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
          const totalHours = order.timeLine?.actualHours || order.timeLine?.allHours;
          return (
            <>
              <StyledLink
                to={`${orderUrl}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    {notificationData?.extendHour?.hours} hours have been added to the order{' '}
                    <b>{` #${order.hashCode}`}</b>. Order total hours are now{' '}
                    {notificationData?.extendHour?.hours + totalHours} and the order end date has
                    been updated to{' '}
                    {dayjs(notificationData.extendHour.endDate).format('DD MMM, YYYY')}.
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  Do you accept the changes?
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1, mr: 1 }}
                      onClick={acceptExtension}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1, mr: 'auto' }}
                      onClick={declineExtension}
                    >
                      Decline
                    </Button>
                  </Box>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      // 17
      case ENotificationsType.CHANGE_ORDER_ACCEPT:
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                Changes to the order <b>{` #${order.hashCode}`}</b> have been accepted by{' '}
                {`${order.worker?.firstName} ${order.worker?.lastName}`}.
              </>
            )}
          </StyledLink>
        );
      // When consultant Accepted Order Extension
      case ENotificationsType.CHANGE_ORDER_EXTEND_HOURS_ACCEPT: {
        if (!isConsultant) {
          return (
            <>
              <StyledLink
                to={`${orderUrl}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Changes to the order <b>{` #${order.hashCode}`}</b> have been accepted by{' '}
                    {`${order.worker?.firstName} ${order.worker?.lastName}`}.
                  </>
                )}
              </StyledLink>
              {notification?.sharedLink && (
                <StyledAction>
                  Please pay the updated balance to confirm the extension.
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() =>
                      navigate(`${ROUTES.PAYMENT}/${notification.sharedLink?.hashCode}`)
                    }
                  >
                    Pay Now
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }
      // 18
      case ENotificationsType.CHANGE_ORDER_REJECT:
        return (
          <>
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  Changes to the order <b>{` #${order.hashCode}`}</b> have not been accepted by{' '}
                  {`${order.worker?.firstName} ${order.worker?.lastName}`}.
                </>
              )}
            </StyledLink>
            <StyledAction>
              Please coordinate with {`${order.worker?.firstName} ${order.worker?.lastName}`} to
              understand what their limitations might be.
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 1, mr: 'auto' }}
                onClick={() => goToChat(order.chat.id)}
              >
                Send Message
              </Button>
            </StyledAction>
          </>
        );
      // When consultant Declined Order Extension
      case ENotificationsType.CHANGE_ORDER_EXTEND_HOURS_REJECT:
        if (!isConsultant) {
          return (
            <>
              <StyledLink
                to={`${orderUrl}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Changes to the order <b>{` #${order.hashCode}`}</b> have not been accepted by{' '}
                    {`${order.worker?.firstName} ${order.worker?.lastName}`}.
                  </>
                )}
              </StyledLink>
              <StyledAction>
                Please coordinate with {`${order.worker?.firstName} ${order.worker?.lastName}`} to
                understand what their limitations might be.
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                  onClick={() => goToChat(order.chat.id)}
                >
                  Send Message
                </Button>
              </StyledAction>
            </>
          );
        }
        return null;
      // When a customer paid for an extended hours
      case ENotificationsType.PAID_ORDER_EXTEND_HOURS:
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                Extended hours for the order <b>{` #${order.hashCode}`}</b> have been successfully
                paid.
              </>
            )}
          </StyledLink>
        );
      // When the Customer changed timeline in the order and a new consultant has been selected
      case ENotificationsType.CANCEL_ORDER:
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                The order <b>{` #${order.hashCode}`}</b> has been cancelled.
              </>
            )}
          </StyledLink>
        );
      case ENotificationsType.RECEIVE_MESSAGE_CHAT: {
        const userName = isConsultant
          ? `${order.customer?.firstName} ${order.customer?.lastName}`
          : `${order.worker?.firstName} ${order.worker?.lastName}`;
        return (
          <StyledAction>
            You received new message from {userName}. Click the button below to view message
            <StyledAction>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 1, mr: 'auto' }}
                onClick={() => goToChat(order.chat.id)}
              >
                Message
              </Button>
            </StyledAction>
          </StyledAction>
        );
      }
      // Fridays email sending to submit Specialist weekly billing
      case ENotificationsType.CREATE_WEEKLY_BILLING: {
        if (isConsultant || isAgency) {
          return (
            <StyledAction>
              Submit your weekly billing to the order <b>{` #${order.hashCode} `}</b>
              {!isAction && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                  onClick={() => goToOrder(EPreviewOrder.PAYMENT_REVIEW)}
                >
                  Submit
                </Button>
              )}
            </StyledAction>
          );
        }
        return null;
      }
      // When a Specialist submit weekly billing and Customer has to confirm
      case ENotificationsType.SUBMITTED_WEEKLY_BILLING: {
        if (!isConsultant) {
          return (
            <>
              <StyledLink
                to={`${orderUrl}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Specialist submited weekly billing to the order <b>{` #${order.hashCode}`}</b>.
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  Please, review and confirm
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => goToOrder(EPreviewOrder.REVIEW)}
                  >
                    Review
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }
      // need to pay weekly
      // Monday email sending to pay for 2 weeks
      case ENotificationsType.CREATE_WEEKLY_BILLING_INVOICE: {
        if (!isConsultant) {
          return (
            <StyledAction>
              New invoice for upcoming weeks was created for <b>{` #${order.hashCode} `}</b> order.
              Please, review the invoice
              {notification?.sharedLink && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                  onClick={() => navigate(`${ROUTES.PAYMENT}/${notification.sharedLink?.hashCode}`)}
                >
                  Review
                </Button>
              )}
            </StyledAction>
          );
        }
        return null;
      }
      // need to pay updated time line
      case ENotificationsType.CHANGE_ORDER_UPDATE_TIME_LINE: {
        return (
          <>
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} was changed`}</b>
                </>
              )}
            </StyledLink>
            {!isConsultant && notification?.sharedLink && (
              <StyledAction>
                Please pay the updated balance to confirm timeline update.
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                  onClick={() => navigate(`${ROUTES.PAYMENT}/${notification.sharedLink?.hashCode}`)}
                >
                  Pay
                </Button>
              </StyledAction>
            )}
          </>
        );
      }
      // weekly paying successful
      // When a Customer paid for 2 weeks
      case ENotificationsType.PAID_INVOICE: {
        if (isConsultant) {
          return (
            <StyledLink to={`${orderUrl}`} onClick={() => close()}>
              {msgDom ? undefined : (
                <>
                  The order <b>{` #${order.hashCode} `}</b> paid is now fully confirmed!
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <StyledLink to={`${orderUrl}`} onClick={() => close()}>
            {msgDom ? undefined : (
              <>
                The invoice for an <b>{` #${order.hashCode} `}</b> was Successfully paid
              </>
            )}
          </StyledLink>
        );
      }
      // When the order doesn't receive payment
      case ENotificationsType.ON_HOLD_ORDER: {
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                We wanted to let you know that the order <b>{` #${order.hashCode} `}</b> has been
                put on hold
              </>
            )}
          </StyledLink>
        );
      }
      // When the order doesn't receive payment before the start date
      case ENotificationsType.EXPIRED_ORDER: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  Unfortunately the order <b>{` #${order.hashCode} `}</b> has expired since we
                  didn’t receive any payment from the customer.
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                Unfortunately we didn’t receive payment for your order{' '}
                <b>{` #${order.hashCode} `}</b> and it has now expired!
              </>
            )}
          </StyledLink>
        );
      }
      // When the order doesn't receive payment before the start date
      case ENotificationsType.TERMINATED_ORDER: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  Unfortunately the order <b>{` #${order.hashCode} `}</b> has been terminated since
                  we didn’t receive approved weekly billing from the customer.
                </>
              )}
            </StyledLink>
          );
        }
        return (
          <StyledLink
            to={`${orderUrl}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                Unfortunately we didn’t receive approved weekly billing for your order{' '}
                <b>{` #${order.hashCode} `}</b> and it has now terminated!
              </>
            )}
          </StyledLink>
        );
      }
      // When a Specialist adjusted weekly billing
      case ENotificationsType.ADJUSTED_WEEKLY_BILLING: {
        if (!isConsultant) {
          return (
            <StyledLink
              to={`${orderUrl}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  We wanted to let you know that the weekly billings for an{' '}
                  <b>{` #${order.hashCode} `}</b> order has been adjusted
                </>
              )}
            </StyledLink>
          );
        }
        return null;
      }
      // When a Customer clicked Dispute button
      case ENotificationsType.DISPUTED_WEEKLY_BILLING: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`/order/${order?.id}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  We wanted to let you know that the order <b>{` #${order?.hashCode} `}</b> has been
                  disputed
                </>
              )}
            </StyledLink>
          );
        }
        return null;
      }
      case ENotificationsType.CHANGE_ORDER:
        return (
          <StyledLink
            to={`/order/${order?.id}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                The order <b>{` #${order?.hashCode || order?.name} was changed`}</b>
              </>
            )}
          </StyledLink>
        );

      // When draft order was created
      case ENotificationsType.DRAFT_ORDER_CREATE: {
        if (!isConsultant) {
          return (
            <StyledLink
              to={`${ROUTES.DRAFT_ORDER}/${draftOrder?.id}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  <b>{`${draftOrder?.worker.firstName} ${draftOrder?.worker.lastName}`}</b> has
                  created a new order draft <b>{`#${draftOrder?.id}`}</b>! The specialist&apos;s
                  engagement is for <b>{`${draftOrder?.timeLine.allHours}`}</b> hours starting on{' '}
                  <b>{`${dayjs(draftOrder?.timeLine.startDate).format('DD MMM, YYYY')}`}</b>.
                </>
              )}
            </StyledLink>
          );
        }
        return null;
      }
      // When Customer has approved draft order
      case ENotificationsType.DRAFT_ORDER_APPROVE: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`${ROUTES.DRAFT_ORDER}/${draftOrder?.id}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  <b>{`${draftOrder?.customer?.firstName} ${draftOrder?.customer?.lastName}`}</b>{' '}
                  has approved order draft <b>{`#${draftOrder?.id}`}</b>!
                </>
              )}
            </StyledLink>
          );
        }
        return null;
      }
      // When Customer has declined draft order
      case ENotificationsType.DRAFT_ORDER_DECLINE: {
        if (isConsultant) {
          return (
            <StyledLink
              to={`${ROUTES.DRAFT_ORDER}/${draftOrder?.id}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  <b>{`${draftOrder?.customer?.firstName} ${draftOrder?.customer?.lastName}`}</b>{' '}
                  has declined order draft <b>{`#${draftOrder?.id}`}</b>!
                </>
              )}
            </StyledLink>
          );
        }
        return null;
      }

      // Preorder flow
      case ENotificationsType.PRE_ORDER_CREATE: {
        if (roleName === ERole.LEAD_MANAGER) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> has been created
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }

      case ENotificationsType.PRE_ORDER_PENDING_AGENCY: {
        if (roleName === ERole.LEAD_MANAGER) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> is waiting for{' '}
                    <b>{`${preOrder?.agency?.name}`}</b> review
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }

        if ([ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> is waiting for your review
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }

      case ENotificationsType.PRE_ORDER_AGENCY_ACCEPTED: {
        if (roleName === ERole.LEAD_MANAGER) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> has been accepted by{' '}
                    <b>{`${preOrder?.agency?.name}`}</b>
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }

      case ENotificationsType.PRE_ORDER_UPDATED: {
        if ([ERole.LEAD_MANAGER, ERole.CUSTOMER_ADMIN].includes(roleName)) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> has been updated by{' '}
                    <b>{`${preOrder?.agency?.name}`}</b>
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }

      case ENotificationsType.PRE_ORDER_ACCEPTED: {
        if ([ERole.LEAD_MANAGER, ERole.RESOURCE_MANAGER, ERole.AGENCY_ADMIN].includes(roleName)) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> has been accepted by{' '}
                    <b>{`${preOrder?.customer?.firstName} ${preOrder?.customer?.lastName}`}</b>
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }

      case ENotificationsType.PRE_ORDER_CONFIRMED: {
        return (
          <>
            <StyledLink
              to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
              onClick={() => close()}
              {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
            >
              {msgDom ? undefined : (
                <>
                  Preorder <b>{`#${preOrder?.id}`}</b> has been confirmed. Order will be created and
                  available on the My Orders page
                </>
              )}
            </StyledLink>
            {!isAction && (
              <StyledAction>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mr: 'auto' }}
                  onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                >
                  View
                </Button>
              </StyledAction>
            )}
          </>
        );
      }

      case ENotificationsType.PRE_ORDER_PENDING_APPROVAL: {
        if (roleName === ERole.LEAD_MANAGER) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> is available for Customer review
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        if (roleName === ERole.CUSTOMER_ADMIN) {
          return (
            <>
              <StyledLink
                to={`${ROUTES.PRE_ORDER}/${preOrder?.id}`}
                onClick={() => close()}
                {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
              >
                {msgDom ? undefined : (
                  <>
                    Preorder <b>{`#${preOrder?.id}`}</b> is available for your review
                  </>
                )}
              </StyledLink>
              {!isAction && (
                <StyledAction>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, mr: 'auto' }}
                    onClick={() => navigate(`${ROUTES.PRE_ORDER}/${preOrder?.id}`)}
                  >
                    View
                  </Button>
                </StyledAction>
              )}
            </>
          );
        }
        return null;
      }

      default:
        return (
          <StyledLink
            to={`/order/${order?.id}`}
            onClick={() => close()}
            {...(msgDom ? { dangerouslySetInnerHTML: msgDom } : {})}
          >
            {msgDom ? undefined : (
              <>
                The order <b>{` #${order?.hashCode || order?.name} has changes`}</b>
              </>
            )}
          </StyledLink>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notificationType,
    isAction,
    isConsultant,
    navigate,
    notification.sharedLink,
    order,
    preOrder,
    acceptExtension,
    approveComplete,
    declineComplete,
    declineExtension,
    diffEnd,
    diffStart,
    goToChat,
    goToOrder,
    notificationData.extendHour?.endDate,
    notificationData.extendHour?.hours,
    orderUrl,
  ]);

  return msg;
};

export default useNotifyMsg;
