import React, { FunctionComponent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

import { useAppDispatch } from 'store/hooks';

import { ICustomerStep } from 'models/inner-models';
import { ECustomerRegisterModal, EMAIL_REGEX, ERole } from 'models/consts';
import { EErrorMessages } from 'models/formConstants';

import { fetchMe, login } from 'store/query/user';

import ROUTES from 'router/constants';

import InvisibleIcon from 'styles/icons/InvisibleIcon';
import VisibleIcon from 'styles/icons/VisibleIcon';
import { StyledActionGroup, StyledGrid, StyledRoot, StyledH1, StyledForm } from '../styled';

import { BootstrapInput } from 'components/CustomFields';

const Signin: FunctionComponent<ICustomerStep> = ({ isModal, onChangeCustomerStep }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const [isPasswordVisible, setPasswordVisiblity] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleClickShowPassword = () => {
    setPasswordVisiblity(!isPasswordVisible);
  };

  const navigateBasedOnConsultantStatus = (user: any, navigate: any) => {
    const isConsultant = user?.role?.name === ERole.CONSULTANT;
    if (!isConsultant) {
      navigate(ROUTES.ORDER_LIST);
      return;
    }
    const consultant = user?.consultant;
    const isApproved = consultant?.isApproved;
    const callScheduled = consultant?.callScheduled;
    const registrationStep = consultant?.registrationStep;
    const agencyId = consultant?.agencyId;
    if (isApproved) {
      navigate(ROUTES.ORDER_LIST);
    } else if (!isApproved && (callScheduled || callScheduled === null)) {
      navigate(ROUTES.CONSULTANT);
    } else {
      const registrationPath = `${ROUTES.CONSULTANT_REGISTRATION}/${registrationStep + 1}`;
      const state = agencyId ? { state: { isAgencyAddingUser: true } } : {};
      navigate(registrationPath, state);
    }
  };

  const onSubmit: SubmitHandler<any> = (data: any) => {
    dispatch(login(data))
      .unwrap()
      .then((data: any) => {
        if (data) {
          dispatch(fetchMe())
            .unwrap()
            .then(user => {
              if (user && isModal) {
                onChangeCustomerStep && onChangeCustomerStep(null);
              } else {
                navigateBasedOnConsultantStatus(user, navigate);
              }
            });
        }
      });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const signUpBox = (sx: SxProps | undefined) => (
    <Box mt={2} sx={{ ...{ mb: { lg: '5vh', xs: '30px' } }, ...sx }} justifyContent="space-between">
      <Typography component="span" sx={{ mr: 1 }} variant="body1">
        Don&apos;t have an account?
      </Typography>
      {isModal ? (
        <MuiLink
          color="secondary"
          component={Link}
          to={search}
          onClick={() =>
            onChangeCustomerStep && onChangeCustomerStep(ECustomerRegisterModal.SIGNUP)
          }
          variant="body1"
        >
          Sign Up
        </MuiLink>
      ) : (
        <MuiLink
          data-test="sign-up-link"
          color="primary"
          component={Link}
          to={ROUTES.SIGN_UP}
          variant="body1"
        >
          Sign Up
        </MuiLink>
      )}
    </Box>
  );

  const signInButton = (sx: SxProps | undefined, id: string) => (
    <Button id={id} color="secondary" sx={sx} variant="contained" disabled={!isValid} type="submit">
      Sign In
    </Button>
  );

  return (
    <StyledRoot component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ height: '100%' }}>
        <StyledGrid xs={12} lg={isModal ? 12 : 6}>
          <Box flexGrow={1} mt={{ xs: 2, sm: 0 }}>
            {!isModal && <StyledH1 variant="h1">Sign in</StyledH1>}

            <StyledForm>
              <FormGroup>
                <InputLabel>Email</InputLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      dataTest="email"
                      {...field}
                      inputRef={ref}
                      inputProps={{
                        enterkeyhint: 'next',
                        tabindex: 1,
                        onKeyPress: (e: any) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            document.getElementById('password-input')?.focus();
                          }
                        },
                      }}
                      error={!!errors.email}
                      placeholder="Enter your email"
                      helperText={errors.email?.message || 'Incorrect data'}
                      type="email"
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Invalid email',
                    },
                  }}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Password</FormLabel>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      dataTest="password"
                      {...field}
                      InputProps={{
                        autoComplete: 'current-password',
                      }}
                      inputRef={ref}
                      inputProps={{ id: 'password-input', enterkeyhint: 'done', tabindex: 2 }}
                      error={!!errors.password}
                      placeholder="Enter password"
                      helperText={errors.password?.message || 'Incorrect data'}
                      type={isPasswordVisible ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {isPasswordVisible ? <InvisibleIcon /> : <VisibleIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    minLength: {
                      value: 8,
                      message: EErrorMessages.MIN_LENGTH_PASSWORD,
                    },
                  }}
                />
              </FormGroup>

              <FormGroup
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 1,
                  mt: { xs: '51px' },
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" checkedIcon={<span />} icon={<span />} />}
                  label="Remember me"
                  sx={{ marginLeft: 0 }}
                />
                <MuiLink color="secondary" component={Link} to={ROUTES.FORGOT_PASSWORD}>
                  Forgot Password?
                </MuiLink>
              </FormGroup>
            </StyledForm>

            {signInButton({ mb: 2, display: { xs: 'none', lg: 'block' } }, 'signin-button-lg')}

            {signUpBox({ display: { xs: 'none', lg: 'block' }, mt: 5 })}
          </Box>

          <StyledActionGroup>
            {signUpBox({ display: { xs: 'block', lg: 'none' } })}

            {signInButton(
              {
                mb: { lg: 2, xs: '40px' },
                display: { xs: 'block', lg: 'none' },
                width: { xs: '211px' },
              },
              'signin-button-xs',
            )}
          </StyledActionGroup>
        </StyledGrid>
      </Grid>
    </StyledRoot>
  );
};

export default Signin;
