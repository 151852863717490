import { combineReducers, Action } from '@reduxjs/toolkit';
import agencyReducer, { AgencyState } from './agency';
import userReducer, { UserState } from './user';
import commonReducer, { CommonState } from './common';
import enterpriseReducer, { EnterpriseState } from './enterprise';
import orderCreationReducer, { OrderCreationState } from './order-creation';
import orderReducer, { OrderState } from './order';
import consultantOldReducer, { ConsultantOldState } from './consultantOld';
import consultantReducer, { ConsultantState } from './consultant';
import customerReducer, { CustomerState } from './customer';
import mobileReducer, { MobileState } from './mobile';
import notificationReducer, { NotificationState } from './notification';
import chatReducer, { ChatState } from './chat';
import chatAssistantReducer, { ChatAssistantState } from './chat-assistant';
import companyReducer, { CompanyState } from './company';
import stripeReducer, { StripeState } from './stripe';
import tagsReducer, { TagsState } from './tags';
import orderDraftCreationReducer, { OrderDraftState } from './orderDraftCreation';

import { EUser } from '../constants';

const rootReducer = (state: any, action: Action) => {
  if (action.type == `${EUser.logout}/fulfilled` || action.type == `${EUser.logout}/rejected`) {
    const mobileState = state.mobile;

    //Retain mobile state
    state = { mobile: mobileState };
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  agency: agencyReducer,
  chat: chatReducer,
  chatAssistant: chatAssistantReducer,
  common: commonReducer,
  company: companyReducer,
  consultant: consultantReducer,
  customer: customerReducer,
  enterprise: enterpriseReducer,
  mobile: mobileReducer,
  notification: notificationReducer,
  order: orderReducer,
  stripe: stripeReducer,
  tags: tagsReducer,
  user: userReducer,

  orderCreation: orderCreationReducer,
  consultantOld: consultantOldReducer,
  orderDraftCreation: orderDraftCreationReducer,
});

export default rootReducer;

export type TRootState = {
  agency: AgencyState;
  chat: ChatState;
  chatAssistant: ChatAssistantState;
  common: CommonState;
  company: CompanyState;
  consultant: ConsultantState;
  customer: CustomerState;
  enterprise: EnterpriseState;
  notification: NotificationState;
  mobile: MobileState;
  order: OrderState;
  stripe: StripeState;
  tags: TagsState;
  user: UserState;

  consultantOld: ConsultantOldState;
  orderCreation: OrderCreationState;
  orderDraftCreation: OrderDraftState;
};

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends TRootState {}
}
